import { AxiosError } from 'axios';
import { FormikHelpers, FormikProps } from 'formik';

// @ts-ignore
import ExceptionOf403 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf403';
// @ts-ignore
import ExceptionOf401 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf401';
// @ts-ignore
import ExceptionOf404 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf404';
// @ts-ignore
import ExceptionOf422 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf422';
// @ts-ignore
import ExceptionOf500 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf500';
// @ts-ignore
import ExceptionOf503 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf503';
// @ts-ignore
import ExceptionOf410 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf410';
// @ts-ignore
import ExceptionOf400 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf400';
// @ts-ignore
import ExceptionOf409 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf409';
// @ts-ignore
import ExceptionOf429 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf429';
// @ts-ignore
import ExceptionOfUnknownError from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOfUnknownError';
// @ts-ignore
import ExceptionOfRequestError from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOfRequestError';
import { Api } from '../ApiHandler/ApiInstance';

export type IDefaultExceptionHandler = () => void;

export type IErrorHandlerHelpers = {
  retry: (res: (res: Api<any>) => void) => void;
};

export type IDefaultException = (
  error: AxiosError,
  helpers: {
    requestHelpers: IErrorHandlerHelpers;
  }
) => void;

type ICustomExceptionHelpers = {
  requestHelpers: IErrorHandlerHelpers;
  defaultHandler: IDefaultExceptionHandler;
};

export type ICustomException = (error: AxiosError, helpers: ICustomExceptionHelpers) => any;

interface ICustom422ExceptionHelpers extends Partial<ICustomExceptionHelpers> {
  setFieldError?: FormikHelpers<any>['setFieldError'] | FormikProps<any>['setFieldError'];
  showNotification?: boolean;
}

export type ICustom422Exception = (error: AxiosError, helpers: ICustom422ExceptionHelpers) => any;

export type IErrorHandler = {
  [x in keyof ErrorHandler]: (
    error: AxiosError,
    helpers: {
      requestHelpers: IErrorHandlerHelpers;
      defaultHandler: IDefaultExceptionHandler;
    }
  ) => any;
};

export default class ErrorHandler implements IErrorHandler {
  500: ICustomException | IDefaultException = ExceptionOf500;
  503: ICustomException | IDefaultException = ExceptionOf503;
  404: ICustomException | IDefaultException = ExceptionOf404;
  422: ICustom422Exception | IDefaultException = ExceptionOf422;
  401: ICustomException | IDefaultException = ExceptionOf401;
  403: ICustomException | IDefaultException = ExceptionOf403;
  400: ICustomException | IDefaultException = ExceptionOf400;
  409: ICustomException | IDefaultException = ExceptionOf409;
  410: ICustomException | IDefaultException = ExceptionOf410;
  429: ICustomException | IDefaultException = ExceptionOf429;
  unknown: ICustomException | IDefaultException = ExceptionOfUnknownError;
  requestError: ICustomException | IDefaultException = ExceptionOfRequestError;

  static async handle(error: AxiosError, reqHelpers: IErrorHandlerHelpers, params?: Partial<IErrorHandler>) {
    const errHandler = new ErrorHandler();
    const customErrorHandler = { ...errHandler, ...params } as ErrorHandler;
    let defaultHandler: IDefaultException;
    if (error && error.response?.status) {
      let responseStatus = error.response.status as keyof ErrorHandler;
      defaultHandler = errHandler[responseStatus] as IDefaultException;

      if (customErrorHandler) {
        if (customErrorHandler[responseStatus]) {
          await customErrorHandler[responseStatus](error, {
            requestHelpers: reqHelpers,
            defaultHandler: () =>
              defaultHandler(error, {
                requestHelpers: reqHelpers,
              }),
          });
        } else {
          defaultHandler = errHandler.unknown as IDefaultException;
          await customErrorHandler.unknown(error, {
            requestHelpers: reqHelpers,
            defaultHandler: () =>
              defaultHandler(error, {
                requestHelpers: reqHelpers,
              }),
          });
        }
      }
    } else if (error?.request && !error?.response) {
      defaultHandler = ExceptionOfRequestError as IDefaultException;
      await customErrorHandler.requestError(error, {
        requestHelpers: reqHelpers,
        defaultHandler: () => defaultHandler(error, { requestHelpers: reqHelpers }),
      });
    }
  }
}
