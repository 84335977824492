export class Calculator {
  static addTwoNumbers(first: number, second: number) {
    return Math.round(100 * (first + second)) / 100;
  }

  static subTwoNumbers(first: number, second: number) {
    return Math.round(100 * (first - second)) / 100;
  }

  static roundUp(number: number, precision: number) {
    const precisionPoweredByTen = Math.pow(10, precision);
    return Math.ceil(number * precisionPoweredByTen) / precisionPoweredByTen;
  }
}
