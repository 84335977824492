import React from 'react';
import { AxiosRequestConfig, Method, ResponseType } from 'axios';
import { FormattedMessage } from 'react-intl';

import Request from '@hotelian/utils/ApiHandler/Request';
// @ts-ignore
import axios, { Instance } from 'utils/AxiosInstance';
import ErrorHandler, { IErrorHandler } from '../ErrorHandler';
import { BackendError } from '@hotelian/config/types';
import BackendErrorExtractor from '@hotelian/utils/ErrorHandler/BackendErrorExtractor';

type successType = {
  isFormatted?: false;
  msg: string;
};

type successFormatType = {
  isFormatted: true;
  msgId: string;
};

export interface IRequestOptions {
  showSuccess?: successFormatType | successType;
  errorOptions?: Partial<IErrorHandler>;
}

// export type SSRRequestOptions = (customExceptions: requestOptions['errorOptions']) => requestOptions['errorOptions'];

export interface IApiCallParams {
  url: string;
  params?: any;
  method?: Method;
  data?: any;
  reqInstance?: Request;
  requestOptions?: AxiosRequestConfig | undefined;
  responseType?: ResponseType;
}

export class Api<T = any> {
  url = '';

  params = undefined;
  data = undefined;
  method: Method | undefined = 'get';
  response!: T | any;
  responseHeaders: any = null;
  errors: BackendError | null = null;
  isSuccess: boolean = true;
  requestOptions: AxiosRequestConfig | undefined;
  backendError: BackendErrorExtractor | undefined;
  status!: number;
  reqInstance: Request = Instance;
  responseType: ResponseType | undefined;

  constructor(
    url: string,
    params: any,
    data: any,
    requestOptions: AxiosRequestConfig | undefined,
    method?: Method,
    responseType?: ResponseType
  ) {
    this.url = url;
    this.params = params;
    this.data = data;
    this.requestOptions = requestOptions;
    this.method = method;
    this.responseType = responseType;
  }

  showSuccess(msg: successType | successFormatType) {
    if (window && !this.errors) {
      if (msg.isFormatted) {
        window.openSnackbar(React.createElement(FormattedMessage, { id: msg.msgId }));
      } else {
        window.openSnackbar(msg.msg, 'success');
      }
    }
    return this;
  }

  async request() {
    const { requestInstance: httpRequest } = this.reqInstance;
    try {
      const result = await httpRequest({
        url: this.url,
        params: this.params,
        method: this.method,
        data: this.data,
        ...this.requestOptions,
        responseType: this.responseType,
      } as IApiCallParams);
      this.response = Boolean(result?.data?.result) ? result.data.result : result.data;
      this.responseHeaders = result.headers;
      this.status = 200;
    } catch (e) {
      this.errors = e as BackendError;
      const error = BackendErrorExtractor.handle(e as BackendError);
      this.backendError = error;
      if (error.status) {
        this.status = error.status;
      }
      this.isSuccess = false;
    }
    return this;
  }

  optionsHandler(options: IRequestOptions) {
    if (options.showSuccess) {
      this.showSuccess(options.showSuccess);
    }

    return this;
  }

  async retry(
    onRetry?: (res: Api<any>) => any,
    { reqOptions }: { reqOptions?: IRequestOptions } = {}
  ): Promise<Api<any>> {
    const result = await Api.call(
      {
        url: this.url,
        params: this.params,
        method: this.method,
        data: this.data,
        requestOptions: this.requestOptions,
        reqInstance: this.reqInstance,
      },
      reqOptions
    );
    if (onRetry) {
      onRetry(result);
    }
    return result;
  }

  static call<U>(
    { url, params, method, data, requestOptions, reqInstance, responseType }: IApiCallParams,
    options?: IRequestOptions
  ): Promise<Api<U>> {
    const api = new Api(url, params, data, requestOptions, method, responseType);
    if (reqInstance) api.reqInstance = reqInstance;
    return api.request().then(async req => {
      if (options) {
        req.optionsHandler(options);
      }
      if (req.errors) {
        await ErrorHandler.handle(
          req.errors,
          {
            retry: async onRetry => (req = await req.retry(onRetry, { reqOptions: options })),
          },
          options?.errorOptions
        );
      }

      return req;
    });
  }
}
