import AuthStepForm from './components/login/AuthStepForm';
import React, { useEffect, useState } from 'react';
import SecureStepForm from './components/login/SecureStepForm';
import ResponseHandler from '@hotelian/utils/Facades/ResponseHandler';
import { Api } from '@hotelian/utils/ApiHandler/ApiInstance';
import api from '@hotelian/constants/api';
import AuthLoadingPage from './components/resetPassword/AuthLoadingPage';
import BackendErrorExtractor from '@hotelian/utils/ErrorHandler/BackendErrorExtractor';
import LoginErrorPage from './components/login/LoginErrorPage';
import { isCorporate } from '../../../helpers';

export type TwoFATypes = 'email' | 'SMS';

interface ILoginFormProps {
  initialValues?: { username: string; email: string };
  isModal?: boolean;
  afterLogin?: (res: any) => void;
  newCaptcha?: boolean;
  withRedirectToLastPlace?: boolean;
}

export interface ILoginInformation {
  step?: number;
  verificationCode?: string;
  expirationTime: number;
  masked?: string;
  type?: TwoFATypes;
  email?: string;
  username?: string;
  password?: string;
}

interface ITwoFAStatus {
  twoFACheck?: boolean;
  isTwoFaValid?: boolean;
  twoFAErrorMessage?: string;
}

const LoginForm: React.FC<ILoginFormProps> = ({
  initialValues,
  isModal,
  afterLogin,
  newCaptcha,
  withRedirectToLastPlace,
}) => {
  const isCorporateActive = isCorporate();
  const [loginInformation, setLoginInformation] = useState<ILoginInformation>({
    step: 0,
    email: '',
    username: '',
    password: '',
    verificationCode: '',
    expirationTime: 0,
  });

  const [twoFAStatus, setTwoFAStatus] = useState<ITwoFAStatus>({
    twoFACheck: false,
    isTwoFaValid: false,
    twoFAErrorMessage: '',
  });

  useEffect(() => {
    if (loginInformation.step === 1) send2FACode();
  }, [loginInformation.step]);

  const send2FACode = async () => {
    let data;
    if (isCorporateActive) {
      data = {
        email: loginInformation.email,
        password: loginInformation.password,
      };
    } else {
      data = {
        username: loginInformation.username,
        password: loginInformation.password,
      };
    }
    try {
      const { masked, expiration_time, type } = await ResponseHandler.freeHandling(
        Api.call(
          {
            method: 'POST',
            url: api.b2b.user.twoFA,
            data,
          },
          {
            errorOptions: {
              422: error => {
                const result = error?.response?.data?.result;
                if (Array.isArray(result)) {
                  const messageObj = result?.[0];
                  setTwoFAStatus(prev => ({
                    ...prev,
                    twoFAErrorMessage: messageObj?.message,
                  }));
                }
              },
              404: error => {
                const { message } = BackendErrorExtractor.handle(error);
                if (message) {
                  setTwoFAStatus(prev => ({ ...prev, twoFAErrorMessage: message }));
                }
              },
              500: error => {
                const { message } = BackendErrorExtractor.handle(error);
                if (message) {
                  setTwoFAStatus(prev => ({ ...prev, twoFAErrorMessage: message }));
                }
              },
            },
          }
        )
      );
      setLoginInformation(prev => ({
        ...prev,
        masked,
        type,
        expirationTime: expiration_time,
      }));
      setTwoFAStatus(prev => ({
        ...prev,
        isTwoFaValid: true,
      }));
    } catch (e) {
      setTwoFAStatus(prev => ({ ...prev, isTwoFaValid: false }));
    } finally {
      setTwoFAStatus(prev => ({ ...prev, twoFACheck: true }));
    }
  };

  const loginFormStepHandler = (step: number) => {
    setLoginInformation(prevState => ({ ...prevState, step: step }));
  };

  const loginFormInformationHandler = (information: Record<any, any>) => {
    setLoginInformation(prevState => ({ ...prevState, ...information }));
  };

  return (
    <>
      {loginInformation.step === 0 ? (
        <AuthStepForm
          loginInformation={loginInformation}
          loginFormStepHandler={loginFormStepHandler}
          loginFormInformationHandler={loginFormInformationHandler}
          initialValues={initialValues}
          withRedirectToLastPlace={withRedirectToLastPlace}
          newCaptcha={newCaptcha}
          afterLogin={afterLogin}
          isModal={isModal}
        />
      ) : twoFAStatus.twoFACheck ? (
        twoFAStatus.isTwoFaValid ? (
          <SecureStepForm
            isModal={isModal}
            loginFormStepHandler={loginFormStepHandler}
            loginInformation={loginInformation}
            withRedirectToLastPlace={withRedirectToLastPlace}
            afterLogin={afterLogin}
            send2FACodeHandler={send2FACode}
          />
        ) : (
          <LoginErrorPage errorMessage={twoFAStatus.twoFAErrorMessage} loginFormStepHandler={loginFormStepHandler} />
        )
      ) : (
        <AuthLoadingPage isModal={isModal} />
      )}
    </>
  );
};

export default LoginForm;
