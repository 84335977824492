import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  DialogProps,
  DialogActions,
  Button,
  DialogTitleProps,
  IconButton,
  Typography,
  DialogContentProps,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

interface IHotelianActionButtons {
  text: {
    ok: string | JSX.Element;
    cancel: string | JSX.Element;
  };
  events: {
    onOk: () => void;
    onCancel: () => void;
  };
  disabled?: boolean;
}

interface IHotelianDialog {
  title: string | React.ReactNode;
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  dialogProps?: Partial<Omit<DialogProps, 'open' | 'onClose' | 'children'>>;
  actionButtons?: IHotelianActionButtons;
  withoutCloseButton?: boolean;
  dialogTitleProps?: DialogTitleProps;
  dialogContentProps?: DialogContentProps;
}

export const HotelianDialog: React.FC<IHotelianDialog> = ({
  title,
  open,
  handleClose,
  children,
  dialogProps,
  actionButtons,
  withoutCloseButton = false,
  dialogTitleProps,
  dialogContentProps,
}) => {
  return (
    <Dialog onClose={handleClose} open={open} {...dialogProps}>
      <DialogTitle
        {...dialogTitleProps}
        className={`${withoutCloseButton ? '' : 'd-flex justify-content-between align-items-center'} ${
          dialogTitleProps?.className ?? ''
        }`}
        disableTypography
      >
        <Typography variant="h6">{title}</Typography>
        {!withoutCloseButton ? (
          <IconButton onClick={handleClose} size="small">
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        ) : null}
      </DialogTitle>

      <Divider />

      <DialogContent {...dialogContentProps}>{children}</DialogContent>
      {actionButtons ? (
        <DialogActions>
          <Button variant="outlined" onClick={actionButtons.events.onCancel} disabled={actionButtons?.disabled}>
            {actionButtons.text.cancel}
          </Button>
          <Button
            variant="contained"
            onClick={actionButtons.events.onOk}
            disabled={actionButtons?.disabled}
            color="primary"
          >
            {actionButtons.text.ok}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};
