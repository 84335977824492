import { IActionStrategy, ObserversType } from '../Strategy/broadcastStrategy.interfaces';

const eventName = 'window_message';

interface CustomWindowEvent<T = any> extends Event {
  readonly detail: T;
  initCustomEvent(typeArg: string, canBubbleArg: boolean, cancelableArg: boolean, detailArg: T): void;
}

type WindowEventDetail<EventNames> = { payload?: any; name: EventNames };

export default class WindowObserver<EventNames extends string> implements IActionStrategy<EventNames> {
  observers: ObserversType<EventNames> = {} as any;
  tabId: string | undefined;

  get canHandleCrossTab() {
    return false;
  }

  private static instance: WindowObserver<any>;

  private constructor() {}

  registerListener() {
    const onMessageFire = (event: CustomWindowEvent<WindowEventDetail<EventNames>>) => {
      if (event && event.detail) {
        const { payload, name } = event.detail;
        const chosenEvent = this.observers[name];
        if (chosenEvent) {
          chosenEvent.map(subscriber => {
            if (typeof subscriber.callback === 'function') {
              subscriber.callback(payload);
            } else {
              subscriber.callback.windowCallback(payload);
            }
          });
        }
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener(eventName, onMessageFire as unknown as EventListener);
    }
  }

  static getInstance<T extends string>(): WindowObserver<T> {
    if (!WindowObserver.instance) {
      WindowObserver.instance = new WindowObserver();
    }

    return WindowObserver.instance;
  }

  fire({ name, payload }: { name: EventNames; payload?: any }) {
    const Event = new CustomEvent(eventName, { detail: { payload, name } });
    window.dispatchEvent(Event);
  }
}
