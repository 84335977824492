import React from 'react';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import style from '../style/AuthFormHeader.module.scss';
import { Children } from '@hotelian/config/types';

interface ITwoStepFormHeader {
  title: Children | string;
  description: Children | string;
  isModal?: boolean;
}

const AuthFormHeader: React.FC<ITwoStepFormHeader> = ({ description, title, isModal }) => {
  return (
    <Grid container spacing={1} style={isModal ? { color: '#36414A' } : { color: 'white' }}>
      <Grid item xs={12} className={style.title}>
        <>{typeof title === 'string' ? <FormattedMessage id={title} /> : title}</>
      </Grid>
      <Grid item xs={12} className={style.description}>
        <>{typeof description === 'string' ? <FormattedMessage id={description} /> : description}</>
      </Grid>
    </Grid>
  );
};

export default AuthFormHeader;
