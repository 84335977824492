import { IActionFireOptions, IActionStrategy, ObserversType } from '../Strategy/broadcastStrategy.interfaces';

export default class ServiceWorkerObserver<EventNames extends string> implements IActionStrategy<EventNames> {
  observers: ObserversType<EventNames> = {} as any;
  tabId: string | undefined;

  private static instance: any;

  get canHandleCrossTab() {
    return true;
  }

  private constructor() {}

  static getInstance<T extends string>(): ServiceWorkerObserver<T> {
    if (!ServiceWorkerObserver.instance) {
      ServiceWorkerObserver.instance = new ServiceWorkerObserver();
    }

    return ServiceWorkerObserver.instance;
  }

  registerListener() {
    const onMessageFire = (event: MessageEvent) => {
      const { message } = event as MessageEvent & { message: any };
      if (event && (event.data || message)) {
        if (event.data) {
          const { type, payload, options, tabId } = <
            { type: EventNames; payload: string; options?: IActionFireOptions; tabId: string }
          >event.data;

          const chosenEvent = this.observers[type];

          if (chosenEvent) {
            if (options?.crossTab || (tabId && this.tabId === tabId)) {
              chosenEvent.map(subscriber => {
                if (typeof subscriber.callback === 'function') {
                  subscriber.callback(payload);
                } else {
                  subscriber.callback.serviceWorkerCallback(payload);
                }
              });
            }
          }
        }
      }
    };

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', onMessageFire);
    }
  }

  fire({ name, payload }: { name: EventNames; payload?: any }, { crossTab = true }: IActionFireOptions = {}) {
    if ('serviceWorker' in navigator) {
      if (!this.observers?.[name]) console.warn(`There is no subscriptions for ${name} type`);
      navigator.serviceWorker.ready.then(registration => {
        if (registration) {
          registration.active?.postMessage({ type: name, payload, options: { crossTab }, tabId: this.tabId });
        }
      });
    }
  }
}
