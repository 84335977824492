import { useState, useEffect } from 'react';

const SECOND = 1_000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

let refresher: NodeJS.Timer;

interface IUseTimerOptions {
  interval?: number;
  listenOnDeadlineChanges?: boolean;
  onDeadlineArrive?: () => void;
  isEnabled?: boolean;
}

export default function useTimer(
  deadline: number,
  { interval = SECOND, listenOnDeadlineChanges = true, onDeadlineArrive, isEnabled = true }: IUseTimerOptions = {
    interval: SECOND,
    isEnabled: true,
    listenOnDeadlineChanges: true,
  }
) {
  const [timespan, setTimespan] = useState(new Date(deadline).valueOf() - Date.now().valueOf());

  const days = Math.floor(timespan / DAY);
  const hours = Math.floor((timespan / HOUR) % 24);
  const minutes = Math.floor((timespan / MINUTE) % 60);
  const seconds = Math.floor((timespan / SECOND) % 60);
  const hasDeadlineArrived = days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0;

  const stopTimer = () => clearInterval(refresher);
  const startTimer = () => {
    if (isEnabled) {
      clearInterval(refresher);
      refresher = setInterval(() => {
        setTimespan(_timespan => _timespan - interval);
      }, interval);
    }
  };

  useEffect(() => {
    if (isEnabled && !hasDeadlineArrived) {
      startTimer();
    } else {
      stopTimer();
    }

    return () => {
      clearInterval(refresher);
    };
  }, [interval, isEnabled]);

  useEffect(() => {
    if (hasDeadlineArrived) {
      if (onDeadlineArrive && typeof onDeadlineArrive === 'function') {
        onDeadlineArrive();
      }
      clearInterval(refresher);
    }
  }, [hasDeadlineArrived]);

  /* If the initial deadline value changes */
  useEffect(() => {
    if (listenOnDeadlineChanges) {
      setTimespan(new Date(deadline).valueOf() - Date.now().valueOf());
    }
  }, [deadline]);

  return {
    days,
    hours,
    minutes,
    seconds,
    hasDeadlineArrived,
    restart: startTimer,
  };
}
