import React from 'react';
import { HotelianDialog } from '@hotelian/components';
// @ts-ignore
import GetVerifyCode from '@hotelian/components/GetVerifyCode';
import { Grid, Typography } from '@material-ui/core';
import { useBroadcastContext, useLoadingContext } from 'utils/hooks/contextHooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { b2bEmailVerifyNotifyDisplayTime } from '@hotelian/helpers/storageHelpers';
import { calcSum } from '@hotelian/utils';

export interface IEmailVerifyNoticeLayoutProperties {
  children: React.ReactNode;
}

export const EmailVerifyNoticeLayout = ({ children }: IEmailVerifyNoticeLayoutProperties) => {
  const { loading, setLoading } = useLoadingContext();
  const { observer } = useBroadcastContext();
  const { formatMessage } = useIntl();

  const [isNoticeModalOpen, setIsNoticeModalOpen] = React.useState(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = React.useState(false);

  const closeTheNoticeModal = () => {
    setIsNoticeModalOpen(false);
  };

  const [userData, setUserData] = React.useState<unknown>(null);

  const twentyFourHoursInMS = 24 * 60 * 60 * 1000;

  React.useEffect(() => {
    const NOTIF_EMAIL_VERIFY_OBSERVER_ID = '62a86154-f6c9-4750-9fdd-6a02d12e7871';
    const LOGOUT_USER_OBSERVER_ID = '6f5eb429-5ab8-573a-8a12-31f8c58c0017';
    const TOKEN_EXPIRED_OBESRVER_ID = 'e7d379bf-b4bd-4629-bab0-b48c682a7e24';

    observer.subscribe({
      id: NOTIF_EMAIL_VERIFY_OBSERVER_ID,
      name: 'NOTIF_EMAIL_VERIFY',
      callback(payload) {
        const isEmailVerified = Boolean(payload?.user?.is_email_verified);
        if (isEmailVerified) return;

        const lastDisplayTime = b2bEmailVerifyNotifyDisplayTime.get();

        if (!lastDisplayTime || calcSum([lastDisplayTime, twentyFourHoursInMS]) < Date.now()) {
          setIsNoticeModalOpen(true);
          setUserData(payload.user);
          b2bEmailVerifyNotifyDisplayTime.set(Date.now());
        }
      },
    });

    observer.subscribe({
      id: LOGOUT_USER_OBSERVER_ID,
      name: 'LOGOUT_USER',
      callback() {
        setIsNoticeModalOpen(false);
      },
    });

    observer.subscribe({
      id: TOKEN_EXPIRED_OBESRVER_ID,
      name: 'TOKEN_EXPIRED',
      callback() {
        setIsNoticeModalOpen(false);
      },
    });

    return () => {
      observer.unsubscribeByID(NOTIF_EMAIL_VERIFY_OBSERVER_ID);
      observer.unsubscribeByID(LOGOUT_USER_OBSERVER_ID);
      observer.unsubscribeByID(TOKEN_EXPIRED_OBESRVER_ID);
    };
  }, [observer]);

  return (
    <>
      <HotelianDialog
        title={formatMessage({
          id: 'layout.email-verify-notice.title',
        })}
        open={isNoticeModalOpen}
        handleClose={closeTheNoticeModal}
        actionButtons={{
          text: {
            ok: formatMessage({
              id: 'layout.email-verify-noteice.verify',
            }),
            cancel: formatMessage({
              id: 'layout.email-verify-noteice.cancel',
            }),
          },
          events: {
            onOk: () => {
              setIsVerifyModalOpen(true);
              closeTheNoticeModal();
            },
            onCancel: closeTheNoticeModal,
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              <FormattedMessage id="layout.email-verify-notice.message" />
            </Typography>
          </Grid>
        </Grid>
      </HotelianDialog>

      <GetVerifyCode
        type="email"
        open={isVerifyModalOpen}
        setOpen={setIsVerifyModalOpen}
        loading={loading}
        setLoading={setLoading}
        user={userData}
      />

      {children}
    </>
  );
};
