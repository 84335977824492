import { Box, CircularProgress, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface IAuthLoadingPageProps {
  isModal?: boolean;
}

const AuthLoadingPage: React.FC<IAuthLoadingPageProps> = ({ isModal }) => {
  return (
    <Grid container>
      <Grid item xs={12} className={isModal ? 'd-flex flex-column align-items-center' : undefined}>
        <CircularProgress style={isModal ? { color: '#36414A' } : { color: 'white' }} size={31} />
        <Box className="mt-2" style={isModal ? { color: '#36414A' } : { color: 'white' }}>
          <FormattedMessage id="auth-loading-page.loading" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthLoadingPage;
