import { Field, Form, Formik, FormikHelpers } from 'formik';
import { corporateLoginSchema, loginSchema } from '../../_validationSchema';
import { Box, Button, Grid } from '@material-ui/core';
//@ts-ignore
import { TextInput } from '@hotelian/components/Inputs';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint, faLock, faUser } from '@fortawesome/pro-light-svg-icons';
import Captcha from '../../../../../components/Captcha';
import { FormattedMessage, useIntl } from 'react-intl';
import { removeToken } from '../../../../../utils';
import urls from '../../../../../constants/urls';
import { isCorporate } from '../../../../../helpers';
import { useLoadingContext } from '../../../../../utils/hooks/contextHooks';
import { AuthContext } from '../../../../../contexts';
import useReactRouter from '../../../../../utils/hooks/useReactRouter';
import usePrevious from '@hotelian/hooks/usePrevious';
import AuthFormHeader from '../AuthFormHeader';
import { ILoginInformation } from '../../LoginForm';
import BackendErrorExtractor from '@hotelian/utils/ErrorHandler/BackendErrorExtractor';
import ExceptionOf422 from '../../../../../utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf422';

interface IAuthStepForm {
  loginInformation: ILoginInformation;
  loginFormStepHandler: (step: number) => void;
  loginFormInformationHandler: (information: Record<any, any>) => void;
  initialValues?: Pick<IFormValues, 'username' | 'email'>;
  isModal?: boolean;
  afterLogin?: (res: any) => void;
  newCaptcha?: boolean;
  withRedirectToLastPlace: any;
}

interface IFormValues {
  email: string;
  username: string;
  password: string;
  captcha_token: string;
  captcha_value: string;
}

const AuthStepForm: React.FC<IAuthStepForm> = ({
  loginInformation,
  loginFormStepHandler,
  loginFormInformationHandler,
  initialValues,
  isModal,
  afterLogin,
  newCaptcha,
  withRedirectToLastPlace,
}) => {
  const { formatMessage } = useIntl();
  const { loading, setLoading } = useLoadingContext();
  const { signin, setOpenSessionExpiredModal } = useContext(AuthContext);

  const { navigate } = useReactRouter();

  const [revalidateCaptcha, setRevalidateCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');

  const getNewCaptcha = () => {
    setRevalidateCaptcha(prevState => !prevState);
  };

  const prevNewCaptcha = usePrevious(newCaptcha);

  useEffect(() => {
    if (newCaptcha === true && prevNewCaptcha !== undefined) {
      getNewCaptcha();
    }
  }, [newCaptcha]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleSubmit = async (values: IFormValues, { setFieldError }: FormikHelpers<IFormValues>) => {
    if (isCorporateActive) {
      loginFormInformationHandler({ email: values.email, password: values.password });
    } else {
      loginFormInformationHandler({ username: values.username, password: values.password });
    }
    return signin({ ...values, captcha_token: captchaToken }, setFieldError, {
      goToHome: !isModal,
      redirectToLastPlace: withRedirectToLastPlace,
      requestOptions: {
        errorOptions: {
          409: (error: any) => {
            const { code } = BackendErrorExtractor.handle(error);
            if (code === 1225816) {
              loginFormStepHandler(1);
            }
          },
          422: (error: any) => {
            ExceptionOf422(error, { setFieldError });
          },
        },
      },
    })
      .then((res: any) => {
        if (!res) {
          getNewCaptcha();
        } else {
          if (typeof afterLogin === 'function') {
            afterLogin(res);
          }
        }
        return res;
      })
      .catch((err: any) => {
        getNewCaptcha();
      });
  };

  const isCorporateActive = isCorporate();

  return (
    <>
      <Box sx={{ mb: '60px' }}>
        <AuthFormHeader
          isModal={isModal}
          title="Login.header.sing-in"
          description={isModal ? 'login.user-idle-modal.title' : 'Login.header.description'}
        />
      </Box>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          email: initialValues?.email ?? loginInformation?.email ?? '',
          username: initialValues?.username ?? loginInformation?.username ?? '',
          password: loginInformation?.password ?? '',
          captcha_token: '',
          captcha_value: captchaToken,
        }}
        validationSchema={isCorporateActive ? corporateLoginSchema : loginSchema}
        validateOnBlur={false}
      >
        {({ setFieldValue }) => (
          <Form>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} className="mb-2">
                  <Field
                    name={isCorporateActive ? 'email' : 'username'}
                    component={TextInput}
                    placeholder={formatMessage({
                      id: isCorporateActive ? 'login.email.placeholder' : 'login.username.placeholder',
                    })}
                    startAdornment={
                      <Fragment>
                        <FontAwesomeIcon
                          icon={faUser}
                          className={`mr-3 ${isModal ? 'text-muted' : 'color-theme'}`}
                          style={{ opacity: isModal ? 0.5 : undefined }}
                        />
                      </Fragment>
                    }
                    disabled={isModal}
                  />
                </Grid>
                <Grid item xs={12} className="mb-2">
                  <Field
                    name="password"
                    component={TextInput}
                    type="password"
                    placeholder={formatMessage({
                      id: 'login.password.placeholder',
                    })}
                    startAdornment={<FontAwesomeIcon icon={faLock} className="mr-3 color-theme" />}
                    autoFocus={isModal}
                  />
                </Grid>
                <Grid item xs={12} className="mb-2">
                  <Grid container spacing={1} wrap="nowrap">
                    <Grid item className="flex-grow-1">
                      <Field
                        name="captcha_value"
                        component={TextInput}
                        placeholder={formatMessage({
                          id: 'login.captcha.placeholder',
                        })}
                        startAdornment={<FontAwesomeIcon icon={faFingerprint} className="mr-3 color-theme" />}
                      />
                    </Grid>

                    <Grid item>
                      <Captcha
                        key={String(revalidateCaptcha)}
                        setToken={(token: string) => {
                          setCaptchaToken(token);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={`${isModal ? '' : 'mb-2'} font-weight-bolder mb-4`}>
                  <Button
                    style={isModal ? { color: '#092A91' } : { color: 'white' }}
                    className="p-0 bg-transparent text-decoration-underline font-weight-bolder"
                    onClick={() => {
                      setOpenSessionExpiredModal(false);
                      removeToken();
                      navigate(urls.user.resetPassword);
                    }}
                  >
                    <FormattedMessage id="Login.forget-password" />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={loading}
                    color="secondary"
                    variant="contained"
                    size="large"
                    type="submit"
                    className="w-100 d-block b2b-auth-layout-submit-button"
                  >
                    <FormattedMessage id="login.login" />
                  </Button>
                </Grid>
                {isModal && (
                  <Grid item xs={12} style={{ color: '#092A91' }} className="text-start mt-5">
                    <Button
                      style={isModal ? { color: '#092A91' } : { color: 'white' }}
                      className="p-0 bg-transparent text-decoration-underline font-weight-bolder"
                      onClick={() => {
                        setOpenSessionExpiredModal(false);
                        removeToken();
                        navigate(urls.user.login);
                      }}
                    >
                      <FormattedMessage id="login.user-idle-modal.switch-account" />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AuthStepForm;
