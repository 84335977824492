import React from 'react';
import { MaintenanceContext } from './index';
import { useBroadcastContext } from 'utils/hooks/contextHooks';

interface IMaintenance {
  title: string;
  description: string;
}

interface IMaintenanceContextProviderProperties {
  children: React.ReactNode;
}

export const MaintenanceContextProvider = ({ children }: IMaintenanceContextProviderProperties) => {
  const [maintenanceInfo, setMaintenanceInfo] = React.useState<IMaintenance | null>(null);

  const { observer } = useBroadcastContext();

  const enableMaintenanceMode = (values: IMaintenance) => {
    setMaintenanceInfo(values);
  };

  React.useEffect(() => {
    observer.subscribe({
      name: 'BACKEND_MAINTENANCE',
      callback: payload => {
        enableMaintenanceMode({
          title: payload?.name,
          description: payload?.message,
        });
      },
      id: '20a37cd0-8f74-44cc-b61a-1d7f16a9cf9e',
    });
  }, [observer]);

  return (
    <MaintenanceContext.Provider
      value={{ maintenanceInfo, enableMaintenanceMode, isMaintenanceMode: !!maintenanceInfo }}
    >
      {children}
    </MaintenanceContext.Provider>
  );
};

export default MaintenanceContextProvider;
