import { Config, sanitize } from 'isomorphic-dompurify';
import copy from 'copy-to-clipboard';

import { allowedHTMLTagsToParse, imageQualities } from '../constants/defaultValues';
import { CSSDeclaration } from '@hotelian/config/interfaces';

const utils = {
  /**
   * make standard query params
   * @author HotelianCom
   * @param {String} urlPart like name of a hotel
   * @returns replace " " with "-" in url
   */
  urlFormatter: (urlPart: string) => {
    return Boolean(urlPart) && isString(urlPart)
      ? encodeURIComponent(urlPart?.replace(/ /g, '-'))?.toLowerCase()
      : urlPart;
  },
  /**
   * XSS Sanitizer
   * @author HotelianCom
   * @param {String} html raw HTML as a string or a Node element
   * @param {Object} config an optional config to pass to sanitizer
   * @returns {String} a safe, sanitized HTML
   */
  ignoreXSS: (html: string | Node, config?: Config) =>
    sanitize(html, {
      FORCE_BODY: true,
      ADD_ATTR: ['target'],
      ALLOWED_TAGS: allowedHTMLTagsToParse,
      ...config,
    }),
  /**
   * linkify
   * @author HotelianCom
   * @param {String} string the string that you need to linkify it
   * @returns replace urls to html tags
   */
  linkifier: (string: string) => {
    return string?.replace(
      /(https?:\/\/[^\s]+)/g,
      txt => `<a target="_blank" rel="noreferrer noopener" href="${txt}">${txt}</a>`
    );
  },
  /**
   * make normal string from URL params
   * @author HotelianCom
   * @param {String} urlPart like name of a hotel
   * @returns replace " " with "-" in url
   */
  urlParamsDecoder: (urlPart: string) => (Boolean(urlPart) ? urlPart?.replace('-', ' ') : urlPart),
  /**
   * copy a text to clipboard
   * @author HotelianCom
   * @param {String} text
   */
  copyToClipBoard: (text: string) => copy(text, { format: 'text/plain' }),
  /**
   * create array from object
   * @author HotelianCom
   * @param {Object} obj {key:value, ...}
   * @returns {Array} {key:string, value:*}[]
   */
  createArrayFromObject: (obj: any) => Object.keys(obj ?? {})?.map(key => ({ key, value: obj[key] })) ?? [],
  /**
   * create object from array
   * @author HotelianCom
   * @param {Array} arr {key:string, value:*}[]
   * @returns {Object} {key:value, ...}
   */
  createObjectFromArray: (arr: any[]) =>
    arr.reduce((acc, curr) => {
      const currentElementOfObject = { [curr['key']]: curr.value };
      return { ...acc, ...currentElementOfObject };
    }, {}),
  /**
   * exchange url to file
   * @author HotelianCom
   * @param {String} dataUrl
   * @param {String} filename name of returned file
   * @returns final file
   */
  dataURLtoFile: (dataUrl: string, filename: string) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },
  /**
   * change index of an element in array
   * @author HotelianCom
   * @param {Array} arr
   * @param {Number} old_index
   * @param {Number} new_index
   * @returns final array
   */
  arrayMover: (arr: any[], old_index: number, new_index: number) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  },
  /**
   * put "," between every 3 numbers
   * @author HotelianCom
   * @param {Number} number
   * @param {Object} options
   * @returns final number
   * */
  commaBetween: (number: number | string, options?: Intl.NumberFormatOptions) => {
    if (number && typeof Number(number) === 'number') {
      return Number(number).toLocaleString(undefined, options);
    } else {
      return number;
    }
  },
  /**
   * find count decimals of a number
   * @author HotelianCom
   * @param {Number} number
   * @returns count decimals
   * */
  countDecimals: (number: number) => {
    if (Math.floor(number.valueOf()) === number.valueOf()) return 0;
    return number.toString().split('.')[1].length || 0;
  },
  /**
   * get browser scrollbar width
   * @author HotelianCom
   * @returns scrollbar width
   */
  getScrollbarWidth: () => {
    // Creating invisible container
    const outer: HTMLDivElement & { style: CSSDeclaration } = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);
    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);
    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    // Removing temporary elements from the DOM
    outer.parentNode?.removeChild(outer);
    return scrollbarWidth;
  },
  /**
   * validate dangerous texts
   * @author HotelianCom
   * @param {String} str
   * @returns {Boolean} is dangerous or not
   */
  filterXSS: (str: string) => {
    return /<script[\s\S]*?>[\s\S]*?<\/script>/gi.test(str);
  },
  /**
   * parse dynamic texts replace "%d%" with strings
   * @author HotelianCom
   * @param {String} text
   * @param {Array} replacedTexts
   * @return {String} final text
   */
  parseDynamicTexts: (text: string, replacedTexts: string[]) => {
    if (!replacedTexts.length && !Array.isArray(replacedTexts)) {
      throw Error('replacedText must be an array');
    }
    let alternateText = String(text);
    replacedTexts.forEach(el => {
      alternateText = alternateText.replace('%d%', el);
    });
    return alternateText;
  },
  /**
   * parse dynamic texts replace "%d%" with strings
   * @author HotelianCom
   * @param {Array} arr
   * @param {String} key
   * @return {Object} arr grouped by key
   */
  groupBy: (arr: any[], key: number | string) => {
    return arr.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },
  /**
   * get parsed jwt
   * @author HotelianCom
   * @param {String} token jwt token
   * @returns {Object} parsed jwt
   */
  parseJwt: (token: string) => {
    if (!Boolean(token)) {
      return;
    }
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  },
  /**
   * get JSON.parse of a value
   * @author HotelianCom
   * @param {any} value
   * @param {any} errorValue
   * @returns {any} parsed value
   */
  getParsedValue: (value: string, errorValue?: string) => {
    try {
      return JSON.parse(value);
    } catch (err) {
      if (Boolean(errorValue)) {
        return errorValue;
      }
      return value;
    }
  },
  /**
   * change quality of an image
   * @author HotelianCom
   * @param {String} url image url
   * @param {String} finalQuality final quality: thumb | small(default) | medium | large
   * @param {String} prevQuality previous quality
   * @returns {String} final url
   */
  changeQuality: (url: string, finalQuality: string, prevQuality = 'small') => {
    if (!imageQualities.includes(finalQuality)) {
      throw new Error(`final quality is invalid`);
    }
    if (!Boolean(url)) {
      return '';
    }
    return url.replace(`name=${prevQuality}`, `name=${finalQuality}`);
  },
  /**
   * get timezone
   * @author HotelianCom
   * @returns {String} timezone
   */
  getTimezone: () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  },

  groupByWithCount: (items: any[], property: string) => {
    const GroupToReturn: any[] = [];
    items.forEach((item, index) => {
      if (index === 0) {
        GroupToReturn.push({ item, count: 1 });
      } else {
        const indexOfItemInArray = GroupToReturn.findIndex(el => el.item[property] === item[property]);

        // it means an item already exists with this property
        if (indexOfItemInArray !== -1) {
          GroupToReturn[indexOfItemInArray] = { item, count: GroupToReturn[indexOfItemInArray].count + 1 };
        } else {
          GroupToReturn.push({ item, count: 1 });
        }
      }
    });
    return GroupToReturn;
  },

  isObject: (obj: any) => Boolean(obj instanceof Object && obj.constructor === Object),
  isString: (str: any) => typeof str === 'string',
  isArray: (array: any) => Array.isArray(array),
  isFunction: (fn: any) => typeof fn === 'function' && fn instanceof Function,
  isNumber: (number: any) => {
    if (isString(number) && number.length > 0) {
      return Number.isInteger(Number(number)) || !isNaN(number);
    }
    return typeof number === 'number';
  },
  resolveByPath: (path: string, obj = self, separator = '.') => {
    const properties: any[] = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
  },
  /**
   * safely calculates addition and subtraction of two numbers
   * @author HotelianCom
   * @param {number} first number
   * @param {number} second number
   * @param {string} type type of calculation, add | sub
   * @returns {number} calculated value
   */
  safeAddAndSub: (first: number, second: number, type: 'add' | 'sub' = 'add') => {
    if (type === 'add') {
      return Math.round(100 * (first + second)) / 100;
    } else if (type === 'sub') {
      return Math.round(100 * (first - second)) / 100;
    }
  },
  /**
   * safely calculate addition and subtraction of two numbers
   * @author HotelianCom
   * @param {any} items number[] | <T>[]
   * @param {string} field field to iterate on items
   * @returns {number} calculated value
   */
  calcSum: (items: any[], field?: string) => {
    if (items.every(isObject) && field) {
      return items.reduce((prev, current) => safeAddAndSub(prev, resolveByPath(field, current)), 0);
    } else if (items.every(isNumber)) {
      return items.reduce((prev, current) => safeAddAndSub(prev, current), 0);
    }
  },
  /**
   * safely calculate addition and subtraction of two numbers
   * @author HotelianCom
   * @param {object} firstObject first object to compare
   * @param {object} secondObject second object to compare
   * @returns {boolean} returns true if something has been changed in these two objects
   */
  hasSomethingChangedInObject: (firstObject: any, secondObject: any) => {
    return JSON.stringify(firstObject) !== secondObject;
  },
  isValidJSONString: (string: any) => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  },
  extractValidDestinationDrafts: (destinations: any) => {
    const copyOfDestinations = { ...destinations };
    copyOfDestinations.items = copyOfDestinations?.items
      ?.filter((destination: any) => {
        // to remove invalid data from array
        return !!(
          isObject(destination?.title) &&
          isObject(destination?.subtitle) &&
          destination?.dest_code &&
          destination?.dest_type
        );
      })
      ?.reduce((prev: any, current: any) => {
        // to remove duplicated items from array
        if (
          prev.find((el: any) => el?.dest_code === current?.dest_code) &&
          prev.find((el: any) => el?.dest_type === current?.dest_type)
        ) {
          return prev;
        }
        return [...prev, current];
      }, []);
    return copyOfDestinations;
  },
  isEllipsisActive: (e: any) => {
    return e?.offsetWidth < e?.scrollWidth;
  },
  extractChangedFormFields: (previousData: any, latestData: any) => {
    const formData: any = {};
    Object.keys(latestData).forEach(el => {
      if (previousData[el] !== latestData[el] && latestData[el] !== undefined) {
        formData[el] = latestData[el];
      }
    });
    const hasFormChanged = Boolean(Object.keys(formData).length > 0);
    return { formData, hasFormChanged };
  },
  removeArrayDuplicatedItems: (data: any[], fieldToFilter?: string) => {
    if (!data) {
      return [];
    }
    if (fieldToFilter) {
      const arrToReturn: any = [];
      data.forEach((item: any) => {
        const itemInArr = arrToReturn.find(
          (el: any) => resolveByPath(fieldToFilter, el) === resolveByPath(fieldToFilter, item)
        );
        if (!itemInArr) {
          arrToReturn.push(item);
        }
      });
      return arrToReturn;
    } else {
      // @ts-ignore
      return [...new Set(data)];
    }
  },
  isValidUrl: (url: string) => {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  },
  isValidInternalUrl: (url: string) => {
    if (!isValidUrl(url)) {
      return false;
    }
    if (typeof window !== 'undefined' && window.location) {
      const { href } = window.location;
      const ourSiteParsedUrl = new URL(href);
      const parsedGivenUrl = new URL(url);
      if (ourSiteParsedUrl.origin == parsedGivenUrl.origin) {
        return true;
      }
    }
    return false;
  },

  reverseArray: <T extends unknown>(array: T[]): T[] => {
    const arrayCpy = array.slice();
    arrayCpy.reverse();

    return arrayCpy;
  },

  convertNewlineToBr: (str: string) => str.replaceAll('\n', '<br />'),
};

export const arrayMover = utils.arrayMover;
export const changeQuality = utils.changeQuality;
export const commaBetween = utils.commaBetween;
export const countDecimals = utils.countDecimals;
export const copyToClipBoard = utils.copyToClipBoard;
export const createArrayFromObject = utils.createArrayFromObject;
export const createObjectFromArray = utils.createObjectFromArray;
export const dataURLtoFile = utils.dataURLtoFile;
export const filterXSS = utils.filterXSS;
export const getParsedValue = utils.getParsedValue;
export const getScrollbarWidth = utils.getScrollbarWidth;
export const getTimezone = utils.getTimezone;
export const groupBy = utils.groupBy;
export const ignoreXSS = utils.ignoreXSS;
export const linkifier = utils.linkifier;
export const parseDynamicTexts = utils.parseDynamicTexts;
export const parseJwt = utils.parseJwt;
export const urlFormatter = utils.urlFormatter;
export const urlParamsDecoder = utils.urlParamsDecoder;
export const groupByWithCount = utils.groupByWithCount;
export const isObject = utils.isObject;
export const isString = utils.isString;
export const isArray = utils.isArray;
export const isFunction = utils.isFunction;
export const isNumber = utils.isNumber;
export const safeAddAndSub = utils.safeAddAndSub;
export const calcSum = utils.calcSum;
export const resolveByPath = utils.resolveByPath;
export const hasSomethingChangedInObject = utils.hasSomethingChangedInObject;
export const isValidJSONString = utils.isValidJSONString;
export const extractValidDestinationDrafts = utils.extractValidDestinationDrafts;
export const isEllipsisActive = utils.isEllipsisActive;
export const extractChangedFormFields = utils.extractChangedFormFields;
export const removeArrayDuplicatedItems = utils.removeArrayDuplicatedItems;
export const isValidUrl = utils.isValidUrl;
export const isValidInternalUrl = utils.isValidInternalUrl;
export const rounderNumberUp = utils.rounderNumberUp;
export const reverseArray = utils.reverseArray;
export const convertNewlineToBr = utils.convertNewlineToBr;

export default utils;
