import AuthFormHeader from '../AuthFormHeader';
import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { useLanguageContext } from '../../../../../utils/hooks/contextHooks';

interface ILoginErrorPage {
  errorMessage?: string;
  loginFormStepHandler: (step: number) => void;
}
const LoginErrorPage: React.FC<ILoginErrorPage> = ({ errorMessage, loginFormStepHandler }) => {
  const { direction } = useLanguageContext();
  return (
    <>
      <AuthFormHeader
        title={
          <>
            <FontAwesomeIcon icon={faCircleXmark} className="mr-2" color="red" />
            <FormattedMessage id="auth-error-page.header" />
          </>
        }
        description={errorMessage}
      />
      <Grid item xs={12}>
        <Grid item style={{ marginTop: '150px' }}>
          <Button
            className="text-white p-0 bg-transparent"
            onClick={() => {
              loginFormStepHandler(0);
            }}
          >
            <FontAwesomeIcon icon={direction === 'ltr' ? faArrowLeft : faArrowRight} className="mr-1" />
            <Box className="text-decoration-underline">
              <FormattedMessage id="Login.check-email.back-to-login-btn" />
            </Box>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginErrorPage;
