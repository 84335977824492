import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Button,
  Collapse,
} from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { AuthContext, UIContext } from 'contexts';
import api from '@hotelian/constants/api';
import { IncreaseBalanceSchema } from 'helpers/validations';
import PriceComponent from '@hotelian/components/PriceComponent';
import { Api } from '@hotelian/utils/ApiHandler/ApiInstance';
import ResponseHandler from '@hotelian/utils/Facades/ResponseHandler';
import BackendErrorExtractor from '@hotelian/utils/ErrorHandler/BackendErrorExtractor';
import ExceptionOf422 from '../utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf422';
import { notAllowedToBookCodes } from '@hotelian/constants/backend-codes/reserve';
import { useLoadingContext } from '../utils/hooks/contextHooks';
import {
  addGatewayFeeToPrice,
  getGatewayByName,
} from '@hotelian/utils/common/gatewayFee';
import {
  increaseBalanceGatewayAdapter,
  gatewayImageAdapter,
} from '@hotelian/helpers/gatewayAdapter';
import {
  FormikGateways,
  CommaSeparatedInput,
  HotelianDialog,
} from '@hotelian/components';
import { isPricesEqual } from '../utils';
import { PriceChangeDialog } from './PriceChangeDialog';

const priceChangeDialogStatusDefaultValue = {
  isOpen: false,
  redirectURL: null,
  initialPrice: null,
  finalPrice: null,
};

const IncreaseBalanceDialog = ({ open, toggle }) => {
  const { formatMessage } = useIntl();
  const { getUserDetail } = useContext(AuthContext);
  const { loading, setLoading } = useLoadingContext();
  const { handleOpen403Dialog } = useContext(UIContext);

  const [userData, setUserData] = useState(null);
  const [priceChangeDialogStatus, setPriceChangeDialogStatus] = useState(
    priceChangeDialogStatusDefaultValue
  );

  const handleRedirect = async (url) => {
    setLoading(true);
    window.location.assign(url);
  };

  useEffect(() => {
    if (open) {
      getUserDetail().then(setUserData);
    } else {
      window.setTimeout(() => {
        setUserData(null);
      }, 500);
    }
  }, [open]);

  const handleSubmit = async (values, { setFieldError }) => {
    const { amount, gateway } = values;
    const body = { amount, gateway };

    try {
      setLoading(true);

      const { redirect_id, amount, currency, mapped_amount, mapped_currency } =
        await ResponseHandler.freeHandling(
          Api.call(
            { url: api.b2b.user.transaction, data: body, method: 'post' },
            {
              errorOptions: {
                422: (error) => {
                  ExceptionOf422(error, { setFieldError });
                },
                403: (error, { defaultHandler }) => {
                  const { code, name, message } =
                    BackendErrorExtractor.handle(error);
                  if (notAllowedToBookCodes.includes(code)) {
                    handleOpen403Dialog(true, {
                      title: name,
                      message,
                    });
                  } else {
                    defaultHandler();
                  }
                },
              },
            }
          )
        );

      const { url } = await ResponseHandler.freeHandling(
        Api.call({
          url: api.b2b.user.redirectTransaction,
          params: { redirect_id },
        })
      );

      if (
        isPricesEqual(
          { amount, currency },
          { amount: mapped_amount, currency: mapped_currency }
        )
      ) {
        handleRedirect(url);
      } else {
        setLoading(false);
        setPriceChangeDialogStatus({
          isOpen: true,
          redirectURL: url,
          initialPrice: { amount, currency },
          finalPrice: { amount: mapped_amount, currency: mapped_currency },
        });
      }
    } catch (_err) {
      setLoading(false);
    }
  };

  const gateways = userData?.available_gateways;

  return (
    <Fragment>
      {priceChangeDialogStatus.initialPrice &&
      priceChangeDialogStatus.finalPrice ? (
        <PriceChangeDialog
          initialPrice={{
            amount: priceChangeDialogStatus.initialPrice.amount,
            currency: priceChangeDialogStatus.initialPrice.currency,
          }}
          finalPrice={{
            amount: priceChangeDialogStatus.finalPrice.amount,
            currency: priceChangeDialogStatus.finalPrice.currency,
          }}
          isOpen={priceChangeDialogStatus.isOpen}
          onClose={() => {
            if (!loading) {
              setPriceChangeDialogStatus(priceChangeDialogStatusDefaultValue);
            }
          }}
          onVerify={() => {
            handleRedirect(priceChangeDialogStatus.redirectURL);
          }}
          isOkButtonDisabled={loading}
          isCancelButtonDisabled={loading}
        />
      ) : null}

      <HotelianDialog
        open={open}
        handleClose={toggle}
        dialogProps={{
          PaperProps: { style: { maxWidth: 400 } },
          fullWidth: true,
        }}
        title={<FormattedMessage id="increase-balance.dialog.title" />}
      >
        {userData ? (
          <div>
            <Formik
              initialValues={{
                amount: '',
                gateway: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={IncreaseBalanceSchema}
            >
              {({ values }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="mb-1">
                        <span className="text-muted mr-2">
                          <FormattedMessage id="all.current-balance" />:
                        </span>
                        <PriceComponent
                          price={userData?.agency?.cash_credit}
                          currency={userData?.agency?.currency}
                          currencyClasses="text-muted mr-2"
                          containerClasses="d-flex align-items-center justify-content-between w-100"
                          priceClasses="h4 font-weight-bold"
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        component={CommaSeparatedInput}
                        name="amount"
                        label={<FormattedMessage id="transaction.amount" />}
                        placeholder={formatMessage({
                          id: 'transaction.increase-balance-amount',
                        })}
                        endAdornment={
                          <span className="color-theme">
                            {userData?.agency?.currency?.content}
                          </span>
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box>
                        <Box pb={1}>
                          <div className="color-theme font-weight-bold">
                            <FormattedMessage id="all.gateway" />
                          </div>
                        </Box>
                        <Field
                          buttonContainerProps={{ xs: 12 }}
                          component={FormikGateways}
                          autoSelect
                          size="small"
                          name="gateway"
                          fullWidth
                          options={increaseBalanceGatewayAdapter(
                            gatewayImageAdapter(gateways)
                          )}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} className="font-weight-bold">
                      <Collapse
                        in={Boolean(
                          values.gateway && values.amount && values.amount > 0
                        )}
                      >
                        <Box>
                          <FormattedMessage id="increase-balance.gateway-cost" />
                          :
                          {getGatewayByName(values.gateway, gateways)
                            ?.fee_percentage ? (
                            <Fragment>
                              <span className="ml-1">
                                +
                                <PriceComponent
                                  price={
                                    addGatewayFeeToPrice(
                                      values.amount,
                                      getGatewayByName(values.gateway, gateways)
                                        ?.fee_percentage
                                    )?.gatewayCost
                                  }
                                  currency={userData?.agency?.currency}
                                  containerClasses="mr-1 d-inline-block"
                                />
                              </span>
                              <span className="font-weight-bold">
                                (
                                {
                                  getGatewayByName(values.gateway, gateways)
                                    ?.fee_percentage
                                }
                                %)
                              </span>
                            </Fragment>
                          ) : (
                            <span className="text-success ml-1">
                              <FormattedMessage id="increase-balance.no-gateway-fee" />
                            </span>
                          )}
                        </Box>
                        <Box>
                          <FormattedMessage id="increase-balance.final-price" />
                          :
                          <PriceComponent
                            price={
                              addGatewayFeeToPrice(
                                values.amount,
                                getGatewayByName(values.gateway, gateways)
                                  ?.fee_percentage
                              )?.finalPrice
                            }
                            currency={userData?.agency?.currency}
                            containerClasses="ml-1 d-inline-block"
                          />
                        </Box>
                      </Collapse>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth
                        size="large"
                        className="my-2"
                        disabled={loading}
                      >
                        <FormattedMessage id="all.submit" />
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <Box py={20} className="d-flex justify-content-center">
            <CircularProgress />
          </Box>
        )}
      </HotelianDialog>
    </Fragment>
  );
};

export default IncreaseBalanceDialog;
