import { isString, isValidJSONString } from '@hotelian/utils';
import { sleep } from './index';
import cookies, { CookieAttributes } from 'js-cookie';
import { cookiesExpireTime } from '@hotelian/constants/defaultValues';

const storageKeyTypes = [
  'redirect_url',
  'current_search',
  'search_expiration',
  'B2B_CURRENCY',
  'B2B_USER',
  'base_data',
  'access_token',
  'B2B_USER_LOCALE',
  'destination_draft',
  'nationality_draft',
  'user',
  'final_full_oc_searchId',
  'focus_on_availability',
  'currency',
  'user_locale',
  'captcha_expiration',
  'tab_id',
  'user_country_code',
  'b2bEmailVerifyNotifyDisplayTime',
] as const;

const session = 'session';
const local = 'local';
const cookie = 'cookie';

interface IStorages {
  local: typeof localStorage | undefined;
  session: typeof sessionStorage | undefined;
  cookie: typeof cookie | undefined;
}

let supportedStorage: IStorages;
try {
  supportedStorage = {
    local: localStorage,
    session: sessionStorage,
    cookie: cookie,
  };
} catch (e) {
  supportedStorage = {
    local: undefined,
    session: undefined,
    cookie: cookie,
  };
}

export class BrowserStorage {
  storageKey = '';
  storageType: 'local' | 'session' | 'cookie' = 'local';
  values = null;

  constructor(key: (typeof storageKeyTypes)[number], type: BrowserStorage['storageType']) {
    this.storageKey = key;
    this.storageType = type;
    if (!supportedStorage[type])
      // throw new Error(`storage type is not supported. supported storages: ${Object.keys(supportedStorage).join(', ')}`);
      this.storageType = type;
  }

  async waitForStorageToLoad() {
    while (!supportedStorage[this.storageType]) {
      await sleep(100);
    }
  }

  async asyncGet() {
    await this.waitForStorageToLoad();
    let value;
    if (this.storageType === 'cookie') {
      value = cookies.get(this.storageKey);
    } else {
      value = supportedStorage[this.storageType]?.getItem(this.storageKey);
    }
    return Promise.resolve(value && isValidJSONString(value) ? JSON.parse(value) : isString(value) ? value : null);
  }

  get() {
    let value;
    if (this.storageType === 'cookie') {
      value = cookies.get(this.storageKey);
    } else {
      value = supportedStorage[this.storageType]?.getItem(this.storageKey);
    }
    return value && isValidJSONString(value) ? JSON.parse(value) : isString(value) ? value : null;
  }

  async set(values: any, options?: CookieAttributes) {
    await this.waitForStorageToLoad();
    if (this.storageType === 'cookie') {
      cookies.set(
        this.storageKey,
        typeof values === 'string' ? values : JSON.stringify(values),
        options ?? { expires: cookiesExpireTime }
      );
    } else {
      supportedStorage[this.storageType]?.setItem(
        this.storageKey,
        typeof values === 'string' ? values : JSON.stringify(values)
      );
    }
  }
  async remove() {
    await this.waitForStorageToLoad();
    if (this.storageType === 'cookie') {
      cookies.remove(this.storageKey);
    } else {
      supportedStorage[this.storageType]?.removeItem(this.storageKey);
    }
  }
}

export const redirectUrlStorage = new BrowserStorage('redirect_url', session);
export const currentSearchStorage = new BrowserStorage('current_search', session);
export const searchExpirationStorage = new BrowserStorage('search_expiration', session);
export const currencyStorage = new BrowserStorage('currency', cookie);
export const b2bUserStorage = new BrowserStorage('B2B_USER', local);
export const baseDataStorage = new BrowserStorage('base_data', local);
export const accessTokenStorage = new BrowserStorage('access_token', cookie);
export const userLocaleStorage = new BrowserStorage('user_locale', cookie);
export const destinationDraftStorage = new BrowserStorage('destination_draft', local);
export const nationalityDraftStorage = new BrowserStorage('nationality_draft', local);
export const b2cUserStorage = new BrowserStorage('user', local);
export const finalFullOcSearchIdStorage = new BrowserStorage('final_full_oc_searchId', local);
export const focusOnAvailabilityStorage = new BrowserStorage('focus_on_availability', session);
export const captchaExpirationStorage = new BrowserStorage('captcha_expiration', session);
export const userCountryCodeStorage = new BrowserStorage('user_country_code', cookie);
export const browserTabIdStorage = new BrowserStorage('tab_id', session);
export const b2bEmailVerifyNotifyDisplayTime = new BrowserStorage('b2bEmailVerifyNotifyDisplayTime', local);
