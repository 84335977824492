import React from 'react';

import LanguageContext from './LanguageContext';
import LoadingContext from './LoadingContext';
import CurrencyContext from './CurrencyContext';
import UIContext from './UIContext';
import AuthContext from './AuthContext';
import DraftContext from './DraftContext';
import BaseDataContext from './BaseDataContext';
import BroadcastContext from './BroadcastContext';
import CurrentSearchContext from './CurrentSearchContext';
import MaintenanceContext from './MaintenanceContext';

const ContextsProvider = ({ children }) => {
  return (
    <BroadcastContext>
      <LoadingContext>
        <LanguageContext>
          <MaintenanceContext>
            <CurrencyContext>
              <UIContext>
                <AuthContext>
                  <BaseDataContext>
                    <DraftContext>
                      <CurrentSearchContext>{children}</CurrentSearchContext>
                    </DraftContext>
                  </BaseDataContext>
                </AuthContext>
              </UIContext>
            </CurrencyContext>
          </MaintenanceContext>
        </LanguageContext>
      </LoadingContext>
    </BroadcastContext>
  );
};

export default ContextsProvider;
