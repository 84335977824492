import { Instance } from '../AxiosInstance';
import { accessTokenStorage } from '@hotelian/helpers/storageHelpers';
import { thisMoment } from '@hotelian/utils/CalendarUtils';
import { isInProduction } from '../index';

export const getToken = () => accessTokenStorage.get();

export const setToken = (res: any) => {
  if (res.token) {
    Instance.setAccessToken(res.token);

    accessTokenStorage.set(res.token, {
      // expires: res?.expires_at ? new Date(res?.expires_at) : new Date(thisMoment() + 90 * 24 * 60 * 60 * 1000),
      expires: new Date(thisMoment() + 90 * 24 * 60 * 60 * 1000),
      httpOnly: false,
      secure: isInProduction,
      sameSite: 'lax',
    });
  } else {
    Instance.setAccessToken(null);
    accessTokenStorage.remove();
  }
};

export const removeToken = () => {
  Instance.setAccessToken(null);
  accessTokenStorage.remove();
};
