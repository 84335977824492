import { useContext } from 'react';

import { IBroadcastContext, ILanguageContext, ILoadingContext, IMaintenanceContext } from 'contexts/contexts.interface';
import { BroadcastContext, CurrentSearchContext, LanguageContext, LoadingContext, MaintenanceContext } from 'contexts';
import { IUseCurrentSearchValues } from '@hotelian/hooks/useCurrentSearchValues';

export const useBroadcastContext = () => useContext<IBroadcastContext>(BroadcastContext);
export const useLoadingContext = () => useContext<ILoadingContext>(LoadingContext);
export const useLanguageContext = () => useContext<ILanguageContext>(LanguageContext);

export const useCurrentSearchContext = () => useContext<IUseCurrentSearchValues>(CurrentSearchContext);

export const useMaintenanceContext = () => useContext<IMaintenanceContext>(MaintenanceContext);
