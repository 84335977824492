export * from './token';
export * from './common';
export * from './price';

export const selectText = (node: any) => {
  node = document.getElementById(node);

  // @ts-ignore
  if (document.body.createTextRange) {
    // @ts-ignore
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection?.removeAllRanges();
    selection?.addRange(range);
  } else {
    console.warn('Could not select text in node: Unsupported browser.');
  }
};
