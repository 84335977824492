import Gateway from '@hotelian/utils/models/common/Gateway';

export interface IAdapterGateway extends Gateway {
  supportsCommonForeignerCards?: boolean;
  supportsShetabCards?: boolean;
  descriptionImage?: string;
  translatedNameKey?: string;
}

const imageDirBasePath = '/images/gateways';
const foreignGatewaysImagePath = `${imageDirBasePath}/foreign_gateways.svg`;

export const gatewayImageAdapter = (
  gateways: IAdapterGateway[] | Record<string, IAdapterGateway>
): IAdapterGateway[] => {
  if (!gateways) {
    return [];
  }

  if (Array.isArray(gateways)) {
    return gateways.map(gateway => {
      const transformedGateway = Gateway.create(gateway);
      switch (gateway.name) {
        case 'stripe':
          return {
            ...gateway,
            supportsCommonForeignerCards: true,
            descriptionImage: foreignGatewaysImagePath,
            image: `${imageDirBasePath}/stripe.svg`,
            translatedNameKey: 'payment-gateway.gateways.stripe',
          };
        case 'telr':
          return {
            ...gateway,
            descriptionImage: foreignGatewaysImagePath,
            supportsCommonForeignerCards: true,
            image: `${imageDirBasePath}/telr.svg`,
            translatedNameKey: 'payment-gateway.gateways.telr',
          };
        case 'paypal':
          return {
            ...gateway,
            descriptionImage: foreignGatewaysImagePath,
            supportsCommonForeignerCards: true,
            image: `${imageDirBasePath}/paypal.svg`,
            translatedNameKey: 'payment-gateway.gateways.paypal',
          };
        case 'saman':
          return {
            ...gateway,
            image: `${imageDirBasePath}/saman.svg`,
            supportsShetabCards: true,
            translatedNameKey: 'payment-gateway.gateways.saman',
          };
        case 'saderat':
          return {
            ...gateway,
            image: `${imageDirBasePath}/saderat.svg`,
            supportsShetabCards: true,
            translatedNameKey: 'payment-gateway.gateways.saderat',
          };
        case 'pasargod':
          return {
            ...gateway,
            image: `${imageDirBasePath}/pasargad.svg`,
            supportsShetabCards: true,
            translatedNameKey: 'payment-gateway.gateways.pasargad',
          };
        default:
          return { ...transformedGateway, supportsCommonForeignerCards: false };
      }
    });
  } else {
    return gatewayImageAdapter(Object.values(gateways));
  }
};

export const increaseBalanceGatewayAdapter = (
  gateways: IAdapterGateway[] | Record<string, IAdapterGateway>
): IAdapterGateway[] => {
  if (!gateways) {
    return [];
  }
  if (Array.isArray(gateways)) {
    return gateways.filter(gateway => gateway.can_top_up);
  } else {
    return increaseBalanceGatewayAdapter(Object.values(gateways));
  }
};

export const reserveGatewayAdapter = (
  gateways: IAdapterGateway[] | Record<string, IAdapterGateway>
): IAdapterGateway[] => {
  if (!gateways) {
    return [];
  }
  if (Array.isArray(gateways)) {
    return gateways.filter(gateway => gateway.can_pay_for_reservation);
  } else {
    return reserveGatewayAdapter(Object.values(gateways));
  }
};
