import { createContext } from 'react';

export const LanguageContext = createContext();
export const LoadingContext = createContext();
export const AuthContext = createContext();
export const UIContext = createContext();
export const DraftContext = createContext();
export const BaseDataContext = createContext();
export const CurrencyContext = createContext();

export const BroadcastContext = createContext();
export const CurrentSearchContext = createContext();

export const MaintenanceContext = createContext();
