import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
  Grid,
  Collapse,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { faRedoAlt } from '@fortawesome/pro-regular-svg-icons';

import { CodeValidator, TextInput } from './Inputs';
import api from '@hotelian/constants/api';
import { sendVerificationCode } from '@hotelian/config/send-verification-code';
import { ignoreXSS, parseDynamicTexts } from '@hotelian/utils';
import { Api } from '@hotelian/utils/ApiHandler/ApiInstance';
import Captcha from '@hotelian/components/common/Captcha';
import useTimer from '@hotelian/hooks/useTimer';
import ExceptionOf422 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf422';

export const codeSchema = Yup.object().shape({
  code: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .length(6, <FormattedMessage id="validation.invalidNumber" />),
});

const captchaSchema = Yup.object().shape({
  captcha_value: Yup.string().required(<FormattedMessage id="validation.required" />),
});

const fadeTimeout = 250;

const GetVerifyCode = ({ open, setOpen, afterVerify, loading, user, setLoading, type, description = null }) => {
  // ********************************
  // **  type = "email" | "phone"  **
  // ********************************
  const getTimerDeadline = () => Date.now().valueOf() + 2 * 60 * 1000;

  const [isFirstCaptchaPassed, setIsFirstCaptchaPassed] = useState(false);
  const [randomCaptchaKey, setRandomCaptchaKey] = useState(Math.random());
  const [timerDeadline, setTimerDeadline] = useState(getTimerDeadline());
  const [captchaToken, setCaptchaToken] = React.useState('');

  const { formatMessage } = useIntl();

  const timer = useTimer(timerDeadline, {
    onDeadlineArrive: () => {},
    isEnabled: isFirstCaptchaPassed,
  });

  useEffect(() => {
    if (isFirstCaptchaPassed) {
      setTimerDeadline(getTimerDeadline());
      timer.restart();
    }
  }, [isFirstCaptchaPassed]);

  const handleClose = () => {
    setIsFirstCaptchaPassed(false);
    setOpen(false);
  };

  const handleSubmitPhoneEmail = async (values, { resetForm }) => {
    setLoading(true);
    const { isSuccess } = await Api.call({ url: api.profile.verify, method: 'post', data: values });

    if (isSuccess) {
      handleClose();

      if (type === 'email') {
        window.openSnackbar(<FormattedMessage id="verify.final-message.email" />, 'success');
      }
      if (type === 'phone') {
        window.openSnackbar(<FormattedMessage id="verify.final-message.phone" />, 'success');
      }
      afterVerify(values);
    }
    setLoading(false);
    resetForm();
  };

  const refreshCaptcha = () => setRandomCaptchaKey(Math.random());

  const handleSubmitCaptcha = async values => {
    setLoading(true);

    try {
      const request =
        type === 'email'
          ? { email: user.email, captcha_token: captchaToken, ...values }
          : {
              phone_number: user.phone_number,
              phone_prefix: user.phone_prefix,
              captcha_token: captchaToken,
              ...values,
            };

      await sendVerificationCode(request, {
        errorOptions: {
          422: error => ExceptionOf422(error, { showNotification: true }),
        },
      });

      setIsFirstCaptchaPassed(true);
    } catch (e) {
      refreshCaptcha();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') handleClose();
      }}
      maxWidth="sm"
      keepMounted
      fullWidth
    >
      <DialogTitle>
        <FormattedMessage id="get-verify-code.modal.title" />
      </DialogTitle>
      <Collapse in={!isFirstCaptchaPassed} unmountOnExit timeout={{ enter: fadeTimeout * 2, exit: fadeTimeout }}>
        <div>
          <Formik initialValues={{ captcha_value: '' }} onSubmit={handleSubmitCaptcha} validationSchema={captchaSchema}>
            {({ isSubmitting }) => (
              <Form>
                <DialogContent>
                  <Grid container className="mb-3">
                    {description}
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item style={{ flexGrow: 1 }}>
                      <Field
                        name="captcha_value"
                        component={TextInput}
                        disabled={isSubmitting}
                        placeholder={formatMessage({
                          id: 'register.sign-up.captcha-placeholder',
                        })}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Captcha key={randomCaptchaKey} onLoadCaptcha={({ token }) => setCaptchaToken(token)} />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions>
                  <Button disabled={loading} onClick={handleClose} color="primary">
                    <FormattedMessage id="Cancel" />
                  </Button>
                  <Button disabled={loading || isSubmitting} type="submit" color="primary" variant="contained">
                    <FormattedMessage id="get-verify-code.modal.captcha-step.confirm-button" />
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </div>
      </Collapse>

      <Collapse in={isFirstCaptchaPassed} unmountOnExit timeout={{ enter: fadeTimeout * 2, exit: fadeTimeout }}>
        <div>
          <Formik initialValues={{ code: '' }} validationSchema={codeSchema} onSubmit={handleSubmitPhoneEmail}>
            {() => (
              <Form>
                <DialogContent>
                  <DialogContentText>
                    {type === 'email' && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ignoreXSS(
                            parseDynamicTexts(formatMessage({ id: 'get-verify-code.modal.description.email' }), [
                              user.email,
                            ])
                          ),
                        }}
                      />
                    )}
                    {type === 'phone' && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ignoreXSS(
                            parseDynamicTexts(formatMessage({ id: 'get-verify-code.modal.description.phone' }), [
                              `${user?.phone_prefix}${user?.phone_number}`,
                            ])
                          ),
                        }}
                      />
                    )}
                  </DialogContentText>
                  <Box py={3} className="text-center d-flex justify-content-center">
                    <Field
                      component={CodeValidator}
                      name="code"
                      label={<FormattedMessage id="enter-the-verification-code" />}
                      placeholder={formatMessage({ id: 'verification-code' })}
                      timerCmp={
                        <Grid container spacing={1}>
                          {!timer.hasDeadlineArrived ? (
                            <Grid item className="text--sm-x text-muted font-weight-bold">
                              {timer.minutes} : {timer.seconds}
                            </Grid>
                          ) : null}
                          <Grid item>
                            <IconButton
                              disabled={!timer.hasDeadlineArrived}
                              onClick={() => {
                                setIsFirstCaptchaPassed(false);
                              }}
                              size="small"
                              className="ml-1"
                              color="primary"
                            >
                              <FontAwesomeIcon icon={faRedoAlt} className="fa-sm" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      }
                      autoFocus
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button disabled={loading} onClick={handleClose} color="primary">
                    <FormattedMessage id="Cancel" />
                  </Button>
                  <Button disabled={loading} type="submit" color="primary" variant="contained">
                    <FormattedMessage id="all.verify" />
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </div>
      </Collapse>
    </Dialog>
  );
};
export default GetVerifyCode;
