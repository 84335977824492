import { AxiosError } from 'axios';
import BackendErrorExtractor from '@hotelian/utils/ErrorHandler/BackendErrorExtractor';
import { serverUnderMaintanenceCodes } from '@hotelian/constants/defaultValues';
import { IUIActionNames } from 'utils/interfaces';
import BroadcastStrategy from '@hotelian/utils/Strategy/BroadcastStrategy';

const observer = BroadcastStrategy.getInstance<IUIActionNames>();

// https://http.cat/503
const ExceptionOf503 = (error: AxiosError) => {
  const { code, name, message } = BackendErrorExtractor.handle(error);

  if (code && serverUnderMaintanenceCodes.includes(code)) {
    observer.fire({
      name: 'BACKEND_MAINTENANCE',
      payload: {
        name,
        message,
      },
    });
  }
};

export default ExceptionOf503;
