import { IActionFireOptions, IActionStrategy, IStrategyEvent, ObserversType } from './broadcastStrategy.interfaces';
import ServiceWorkerObserver from '../Observers/ServiceWorker.observer';

export default class BroadcastStrategy<EventNames extends string> {
  strategy: IActionStrategy<EventNames> = ServiceWorkerObserver.getInstance();
  prevStrategy: IActionStrategy<EventNames> | undefined;
  observers: ObserversType<EventNames> = {} as any;
  tabId: string | undefined;
  private static instance: BroadcastStrategy<any>;

  setTabId(tabId: string) {
    this.tabId = tabId;
    this.strategy.tabId = tabId;
  }

  static getInstance<T extends string>(): BroadcastStrategy<T> {
    if (!BroadcastStrategy.instance) {
      BroadcastStrategy.instance = new BroadcastStrategy<T>();
    }

    return BroadcastStrategy.instance;
  }

  get canHandleCrossTab() {
    return this.strategy.canHandleCrossTab;
  }

  setStrategy(strategy: IActionStrategy<EventNames>) {
    this.prevStrategy = this.strategy;
    strategy.observers = this.observers;
    this.strategy = strategy;
    return this;
  }

  registerListener() {
    this.strategy.registerListener();
    return this;
  }
  fire(
    { name, payload }: { name: EventNames; payload?: IStrategyEvent<EventNames>['payload'] },
    { crossTab = true }: IActionFireOptions = {}
  ) {
    this.strategy.fire({ name, payload: { tabId: this.tabId, ...payload } }, { crossTab });
    return this;
  }

  subscribe(event: Omit<IStrategyEvent<EventNames>, 'payload'>) {
    const isNameAvailableInObservers = Boolean(this.observers[event.name]);
    const chosenNameObservers = this.observers?.[event?.name]?.filter(item => item.id !== event.id);
    if (isNameAvailableInObservers) {
      this.observers = { ...this.observers, [event.name]: [...chosenNameObservers, event] };
    } else {
      this.observers = { ...this.observers, [event.name]: [event] };
    }

    this.strategy.observers = this.observers;
    return this;
  }

  unsubscribeByName(eventName: IStrategyEvent<EventNames>['name']) {
    this.observers = Object.entries(this.observers).reduce((acc, [key, value]) => {
      if (key === eventName) {
        return acc;
      }
      return { ...acc, [key]: value };
    }, {} as ObserversType<EventNames>);
    this.strategy.observers = this.observers;
    return this;
  }
  unsubscribeByID(id: IStrategyEvent<EventNames>['id']) {
    this.observers = Object.entries(this.observers)
      .map(([_key, value]) => {
        // @ts-ignore
        return value.filter(sub => sub.id !== id);
      })
      .reduce((prev, curr) => {
        if (curr?.[0]?.name) {
          return { ...prev, [curr[0].name]: curr };
        }
        return prev;
      }, {});

    return this;
  }
}
