import React, { useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-solid-svg-icons';

import styles from './captcha.module.scss';
import General from '@hotelian/utils/models/General';
import useInterval from '@hotelian/hooks/useInterval';
import { captchaExpirationTimeout } from '@hotelian/constants/defaultValues';
import { captchaExpirationStorage } from '@hotelian/helpers/storageHelpers';
// @ts-ignore
import { TextInput } from '@hotelian/components/Inputs';
import CaptchaModel from '@hotelian/utils/models/General/Captcha/captcha.model';

interface ICaptchaProps {
  onLoadCaptcha: (token: CaptchaModel) => void;
}

const Captcha: React.FC<ICaptchaProps> = ({ onLoadCaptcha }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [gettingCaptchaLoading, setGettingCaptchaLoading] = useState(false);
  const getNewCaptcha = async () => {
    setGettingCaptchaLoading(true);
    const response = await General.getCaptcha()
      .then(res => {
        onLoadCaptcha(res);
        setImageUrl(res.imageUrl);
        return res;
      })
      .catch(err => {
        return null;
      });
    setGettingCaptchaLoading(false);
    return response;
  };

  useInterval({
    callbackFn: getNewCaptcha,
    timeout: { minutes: captchaExpirationTimeout },
    storage: captchaExpirationStorage,
    options: { runAtFirstRender: true },
  });

  return (
    <Box className={`${styles['captcha-ctn']}`}>
      <IconButton color="primary" disabled={gettingCaptchaLoading} onClick={getNewCaptcha} size="small">
        <FontAwesomeIcon icon={faSync} />
      </IconButton>
      {imageUrl ? (
        <img src={imageUrl} alt="captcha" className="h-auto" />
      ) : (
        <div className={`bg-foreground-gray ${styles.noCaptchaImage}`} />
      )}
    </Box>
  );
};

export default Captcha;
