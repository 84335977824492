import React, { Fragment } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PriceComponent from '@hotelian/components/PriceComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange } from '@fortawesome/pro-solid-svg-icons';
import { Calculator } from '@hotelian/helpers';

interface PriceObject {
  amount: string;
  currency: string;
}

interface IPriceChangeDialogProps {
  initialPrice: PriceObject;
  finalPrice: PriceObject;
  isOpen: boolean;
  onClose: () => void;
  onVerify: () => void;
  isOkButtonDisabled?: boolean;
  isCancelButtonDisabled?: boolean;
}

interface IPriceChangeDialogFooterProps {
  initialPrice: PriceObject;
  finalPrice: PriceObject;
}

const PriceChangeDialogFooter: React.FC<IPriceChangeDialogFooterProps> = ({
  initialPrice,
  finalPrice,
}) => {
  const ratio =
    initialPrice?.amount && finalPrice?.amount
      ? Number(finalPrice.amount) / Number(initialPrice.amount)
      : null;
  const roundedUpRatio = ratio ? Calculator.roundUp(ratio, 2) : null;
  return (
    <Grid container spacing={2}>
      {roundedUpRatio && (
        <Grid item xs={12} className="font-weight-bold">
          <FormattedMessage id="exchange.footer.ratio" />:{' '}
          <span>{roundedUpRatio}</span>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className="font-weight-bold "
        style={{ fontSize: '0.8rem' }}
      >
        <FormattedMessage id="exchange.footer.description" />
      </Grid>
    </Grid>
  );
};

export const PriceChangeDialog: React.FC<IPriceChangeDialogProps> = ({
  initialPrice,
  finalPrice,
  isOpen,
  onClose,
  onVerify,
  isOkButtonDisabled,
  isCancelButtonDisabled,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle className="bg-theme-secondary text-white d-flex w-100 justify-content-center">
        <FormattedMessage id="reserve.exchange" />
      </DialogTitle>
      <DialogContent>
        {isMobile ? (
          <Fragment>
            <div className="py-4 d-flex justify-content-between flex-nowrap">
              <div className="text-muted">
                <FormattedMessage id="exchange.initial.description" />:
              </div>
              <PriceComponent
                price={initialPrice.amount}
                currency={initialPrice?.currency}
                containerClasses="color-theme font-weight-bold h6"
              />
            </div>
            <div className="confirm-reserve-modal-arrow-ctn">
              <div>
                <FontAwesomeIcon icon={faExchange} />
              </div>
            </div>
            <div className="py-4 d-flex justify-content-between flex-nowrap">
              <div className="text-muted">
                <FormattedMessage id="exchange.final.description" />:
              </div>
              <PriceComponent
                price={finalPrice?.amount}
                currency={finalPrice?.currency}
                containerClasses="color-theme font-weight-bold h6"
              />
            </div>
            <div className="py-4">
              <PriceChangeDialogFooter
                initialPrice={initialPrice}
                finalPrice={finalPrice}
              />
            </div>
          </Fragment>
        ) : (
          <Box py={3}>
            <Grid container alignItems="flex-end">
              <Grid item xs={5}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <span className="small">
                      <FormattedMessage id="exchange.initial.description" />
                    </span>
                  </Grid>
                  <Grid item>
                    <PriceComponent
                      price={initialPrice?.amount}
                      currency={initialPrice?.currency}
                      containerClasses="h5"
                      priceClasses="font-weight-bold"
                      currencyClasses="color-secondary"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <div className="confirm-reserve-modal-arrow-ctn">
                  <div>
                    <FontAwesomeIcon icon={faExchange} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <span className="small">
                      <FormattedMessage id="exchange.final.description" />
                    </span>
                  </Grid>
                  <Grid item>
                    <PriceComponent
                      price={finalPrice.amount}
                      currency={finalPrice.currency}
                      priceClasses="font-weight-bold"
                      currencyClasses="color-secondary"
                      containerClasses="h5"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box mt={3}>
              <PriceChangeDialogFooter
                initialPrice={initialPrice}
                finalPrice={finalPrice}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box px={1} pb={1} className="w-100">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                size="large"
                className="w-100 d-block"
                onClick={onClose}
                color="primary"
                variant="outlined"
                disabled={isCancelButtonDisabled}
              >
                <FormattedMessage id="exchange.cancel" />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                size="large"
                className="w-100 d-block"
                onClick={onVerify}
                color="primary"
                variant="contained"
                disabled={isOkButtonDisabled}
              >
                <FormattedMessage id="exchange.confirm" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
