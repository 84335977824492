import urls from 'constants/urls';
import { lazyWithRetry } from 'utils';

const ViewLogin = lazyWithRetry(() => import(/* webpackChunkName: "views-login" */ 'views/user/login'));
const ViewPersonate = lazyWithRetry(() => import(/* webpackChunkName: "views-personate" */ 'views/user/personate'));
const ViewResetPassword = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-reset-password" */ 'views/user/resetPasswordView')
);

const userRoutes = {
  mainPrefix: urls.user.mainPrefix,
  Layout: undefined,
  routes: [
    { id: 'user/login', Component: ViewLogin, path: urls.user.login },
    { id: 'user/personate', Component: ViewPersonate, path: urls.user.personate },
    { id: 'user/reset-password', Component: ViewResetPassword, path: urls.user.resetPassword },
  ],
};

export default userRoutes;
