import './Maintenance.styles.css';
import React from 'react';
import { Children } from '@hotelian/config/types';
// @ts-ignore
import errorImage from '@hotelian/assets/maintenance-err.svg';
import { useMaintenanceContext } from 'utils/hooks/contextHooks';

export interface MaintenanceLayoutProperties {
  children: Children;
}

export const MaintenanceLayout = ({ children }: MaintenanceLayoutProperties) => {
  const { maintenanceInfo } = useMaintenanceContext();

  React.useEffect(() => {
    if (maintenanceInfo) document.body.classList.add('maintenance_overflowX__disable');
  }, [maintenanceInfo]);

  const getMaintenanceInfoTitleLine = (reverse?: boolean) => (
    <svg
      width="172"
      height="2"
      viewBox="0 0 172 2"
      fill="none"
      className={`maintenance_title_lines ${reverse ? 'maintenance_title_lines__mirror' : ''}`}
    >
      <path d="M172 1H22" stroke="#00247D" strokeWidth="1.2" />
      <path d="M0 1H13.53" stroke="#00247D" strokeWidth="1.2" />
    </svg>
  );

  if (maintenanceInfo)
    return (
      <div className="maintenance_container">
        <img alt="maintenanceInfo" src={errorImage} className="maintenance_image" />
        <span className="maintenance_title_container">
          {getMaintenanceInfoTitleLine()}
          <p className="maintenance_title">{maintenanceInfo.title}</p>
          {getMaintenanceInfoTitleLine(true)}
        </span>
        <p className="maintenance_description">{maintenanceInfo.description}</p>
      </div>
    );

  return children;
};
