import React, { useContext, Fragment } from 'react';

import DesktopAppLayout from './DesktopAppLayout';
import MobileAppLayout from './MobileAppLayout';
import UserIdleModal from 'components/UserIdleModal';
import { AuthContext } from 'contexts';

const AppLayout = ({ children, mobileMode, ...props }) => {
  const { openSessionExpiredModal } = useContext(AuthContext);

  return (
    <Fragment>
      <UserIdleModal open={openSessionExpiredModal} mobileMode={mobileMode} />
      {mobileMode ? (
        <MobileAppLayout {...props}>{children}</MobileAppLayout>
      ) : (
        <DesktopAppLayout {...props}>{children}</DesktopAppLayout>
      )}
    </Fragment>
  );
};

export default AppLayout;
