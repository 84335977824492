import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Button, Grid, Popover } from '@material-ui/core';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { faCoins, faPowerOff } from '@fortawesome/pro-light-svg-icons';

import Footer from 'components/Footer';
import { corporateDesktopUserPopoverMenu, desktopNavMenu, desktopUserPopoverMenu } from 'constants/navMenu';
import UserDetailsCmp from 'components/UserDetailsCmp';
import { AuthContext, LanguageContext, UIContext } from 'contexts';
import useReactRouter from 'utils/hooks/useReactRouter';
import LanguageSwitcher from 'components/LanguageSwitcher';
// import CurrencySwitcher from 'components/CurrencySwitcher';
import logo from '@hotelian/assets/hotelian-logo-topbar.svg';
import { WithPermission } from 'helpers/permission';
// import { isMultiLanguage } from 'constants/default-values';
import B2BAppDesktopErrorBoundary from 'components/ErrorBoundaries/app/B2BAppDesktopErrorBoundary';
import MainErrorBoundary from 'components/ErrorBoundaries/app/MainErrorBoundary';
import urls from '../constants/urls';
import { isCorporate } from '../helpers';

const DesktopAppLayout = ({ children }) => {
  const { headerAppended } = useContext(UIContext);
  const { logout, toggleIncreaseBalance } = useContext(AuthContext);
  const { direction } = useContext(LanguageContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const router = useReactRouter();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleClose();
  }, [router.location.pathname]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const popoverMenuItems = isCorporate() ? corporateDesktopUserPopoverMenu : desktopUserPopoverMenu;

  return (
    <Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction === 'rtl' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: direction === 'rtl' ? 'left' : 'right',
        }}
      >
        <div className="b2b-app-layout-user-popover">
          {popoverMenuItems.map((el, i) => (
            <WithPermission key={i} perms={[...(el.perm ?? [])]}>
              <Link to={el.path}>
                <Button size="large" className="d-block w-100 radius-0">
                  <div className="w-100 d-flex align-items-center mx-2">
                    <div className="mr-3">{el.icon}</div>
                    <div>{el.title}</div>
                  </div>
                </Button>
              </Link>
            </WithPermission>
          ))}
          <Button
            size="large"
            className="d-block w-100 radius-0"
            onClick={() => {
              toggleIncreaseBalance();
              handleClose();
            }}
          >
            <div className="w-100 d-flex align-items-center mx-2">
              <div className="mr-3">
                <FontAwesomeIcon icon={faCoins} />
              </div>
              <div>
                <FormattedMessage id="all.increase-balance" />
              </div>
            </div>
          </Button>
          <Button
            size="large"
            className="d-block w-100 radius-0"
            color="secondary"
            onClick={() => {
              logout();
            }}
          >
            <div className="w-100 d-flex align-items-center mx-2">
              <div className="mr-3">
                <FontAwesomeIcon icon={faPowerOff} />
              </div>
              <div>
                <FormattedMessage id="all.exit" />
              </div>
            </div>
          </Button>
        </div>
      </Popover>
      <div className="b2b-app-layout">
        <header className="b2b-header">
          <div className="b2b-app-header">
            {/* <div className="content__wrapper"> */}
            <div>
              <div className="content__wrapper d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="mr-3 mt-auto">
                    <Link to={urls.app.view} className="b2b-ignore-desktop-link d-block">
                      <img
                        alt="hotelian-logo"
                        src={logo}
                        style={{ height: 20, transform: 'translateY(-2px)' }}
                        className="w-auto"
                      />
                    </Link>
                  </div>
                  <div className="d-flex align-items-center">
                    <nav className="b2b-desktop-nav">
                      <Grid container wrap="nowrap" component="ul" className="list-unstyled m-0 p-0">
                        {desktopNavMenu.map((el, i) => {
                          return (
                            <WithPermission key={i} perms={[...(el.perm ?? [])]}>
                              <Grid item className="d-flex ">
                                <NavLink className="b2b-desktop-nav-item-link" to={el.path}>
                                  {el.title}
                                </NavLink>
                              </Grid>
                            </WithPermission>
                          );
                        })}
                      </Grid>
                    </nav>
                    <div className="d-flex align-items-center ml-3 position-relative">
                      <div>
                        <LanguageSwitcher smallButton />
                      </div>
                      {/*<div className={`${isMultiLanguage ? 'ml-3' : ''}`}>
                        <CurrencySwitcher />
                      </div>*/}
                    </div>
                  </div>
                </div>
                <div>
                  <Button className="text-white" onClick={handleClick}>
                    <UserDetailsCmp btnMode />
                  </Button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="b2b-header-banner">
            {headerAppended ? <div className="content__wrapper">{headerAppended}</div> : null}
          </div>
        </header>
        <main className="content-container">
          <div className="h-100">
            <MainErrorBoundary Layout={B2BAppDesktopErrorBoundary}>{children}</MainErrorBoundary>
          </div>
        </main>
        <Footer />
      </div>
    </Fragment>
  );
};

export default DesktopAppLayout;
