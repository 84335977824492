import React, { Fragment, useEffect } from 'react';
import { Button, ButtonProps, FormHelperText, Grid, GridProps, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { FieldInputProps, FormikProps } from 'formik';

import { IAdapterGateway } from '@hotelian/helpers/gatewayAdapter';

interface IFormikGatewaysProps {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  options: IAdapterGateway[];
  autoSelect?: boolean;
  gatewayButtonProps?: ButtonProps;
  buttonContainerProps?: GridProps;
  onChange?: (value: IAdapterGateway['name'] | null) => void;
  value?: string;
  fullWidth?: boolean;
}

export const FormikGateways: React.FC<IFormikGatewaysProps> = ({
  field,
  form,
  options,
  autoSelect,
  gatewayButtonProps,
  buttonContainerProps,
  onChange,
  value,
  fullWidth,
}) => {
  // it's used to auto select first gateway option
  useEffect(() => {
    if (autoSelect) {
      if (form && field) {
        form.setFieldValue(field.name, options[0]?.name);
      }

      onChange?.(options[0]?.name);
    }
  }, []);

  useEffect(() => {
    if (form && field && form.isSubmitting) {
      form.setFieldTouched(field.name, true);
    }
  }, [form?.isSubmitting]);

  const hasError = form && field ? Boolean(form.errors[field.name] && form.touched[field.name]) : false;

  return (
    <Fragment>
      <Grid container spacing={options?.length > 0 ? 1 : 0}>
        {options?.length > 0 ? (
          <Fragment>
            {options?.map((el, i) => {
              const checked = form && field ? form.values[field.name] === el.name : el.name === value;
              return (
                <Grid
                  key={i}
                  item
                  {...buttonContainerProps}
                  xs={buttonContainerProps?.xs ?? fullWidth ? 12 : undefined}
                  style={{ border: 'none !important', ...buttonContainerProps?.style }}
                >
                  <Button
                    {...gatewayButtonProps}
                    className={`gateway-option ${
                      checked ? 'gateway-option-active' : ''
                    } d-flex overflow-hidden position-relative justify-content-between ${
                      gatewayButtonProps?.className ?? ''
                    }`}
                    style={{
                      maxWidth: fullWidth ? undefined : 285,
                      padding: 0,
                      ...gatewayButtonProps?.style,
                    }}
                    fullWidth={fullWidth}
                    color="primary"
                    onClick={event => {
                      if (!checked) {
                        if (form && field) form.setFieldValue(field.name, el.name);
                        onChange?.(el.name);
                      }
                      gatewayButtonProps?.onClick?.(event);
                    }}
                  >
                    <span className="gateway-radio-container">
                      <input type="radio" checked={checked} />
                    </span>

                    <div className="d-flex flex-row align-items-center px-3 py-1">
                      <span className="gateway-main-image-container mr-4">
                        <img className="gateway-main-image" src={el.image} alt={el.name} />
                      </span>
                      <span className="d-flex flex-column justify-content-start">
                        <span className="gateway-option-text mb-1">
                          <Typography className="font-weight-bold">
                            {el.translatedNameKey ? <FormattedMessage id={el.translatedNameKey} /> : el.name}
                          </Typography>
                        </span>

                        {el.supportsCommonForeignerCards ? (
                          <span className="gateway-description-image-container">
                            <img src={el.descriptionImage} alt={el.name} className="gateway-description-image" />
                          </span>
                        ) : el.supportsShetabCards ? (
                          <span className="gateway-option-text text-muted">
                            <FormattedMessage id="payment-gateway.item.shetab-description" />
                          </span>
                        ) : null}
                      </span>
                    </div>
                  </Button>
                </Grid>
              );
            })}
          </Fragment>
        ) : (
          <div
            className={`text--gray--dark-xx h6 b2b-main-alert reserve-no-gateway w-100 ${
              hasError ? 'with-radius border border-danger' : ''
            }`}
          >
            <FormattedMessage id="payment.no-gateway" />
          </div>
        )}
      </Grid>
      {form && field && hasError && options?.length > 0 ? (
        <FormHelperText className="mt-2 text-danger">{String(form?.errors?.[field.name])}</FormHelperText>
      ) : null}
    </Fragment>
  );
};
