import { isFunction } from '@hotelian/utils';
import { ICustom422Exception } from '@hotelian/utils/ErrorHandler';

const ExceptionOf422: ICustom422Exception = (error, { setFieldError, showNotification }) => {
  if (error.response) {
    error.response.data?.result?.forEach((el: any) => {
      if (showNotification && typeof window !== 'undefined') {
        window.openSnackbar(el.message, 'error');
      } else if (setFieldError && isFunction(setFieldError)) {
        setFieldError(el.field, el.message);
      }
    });
  }
};

export default ExceptionOf422;
