import AuthFormHeader from '../AuthFormHeader';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Box, Button, Grid } from '@material-ui/core';
//@ts-ignore
import { TextInput } from '@hotelian/components/Inputs';
import React, { Fragment, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import useTimer from '@hotelian/hooks/useTimer';
import { useLanguageContext } from '../../../../../utils/hooks/contextHooks';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { ILoginInformation } from '../../LoginForm';
import ExceptionOf422 from '../../../../../utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf422';
import { AuthContext } from '../../../../../contexts';
import { SecureStepFormValidationSchema } from './_validationSecureStepForm';
import { isCorporate } from '../../../../../helpers';

interface ISecureStepFormProps {
  isModal?: boolean;
  loginFormStepHandler: (step: number) => void;
  loginInformation: ILoginInformation;
  withRedirectToLastPlace?: boolean;
  afterLogin?: (res: any) => void;
  send2FACodeHandler: () => Promise<void>;
}

interface IFormValues {
  two_factor_auth_code: string;
}

const SecureStepForm: React.FC<ISecureStepFormProps> = ({
  loginFormStepHandler,
  isModal,
  loginInformation,
  afterLogin,
  withRedirectToLastPlace,
  send2FACodeHandler,
}) => {
  const getTimerDeadline = () => Date.now().valueOf() + 120 * 1000;
  const { direction } = useLanguageContext();
  const { signin } = useContext(AuthContext);
  const [timerDeadline, setTimerDeadline] = useState<number>(getTimerDeadline());
  const { formatMessage } = useIntl();

  const timer = useTimer(timerDeadline, { listenOnDeadlineChanges: true });

  const isCorporateActive = isCorporate();
  const submitHandler = async (value: IFormValues, { setFieldError }: FormikHelpers<IFormValues>) => {
    let data;

    if (isCorporateActive) {
      data = { ...value, email: loginInformation.email, password: loginInformation.password };
    } else {
      data = { ...value, username: loginInformation.username, password: loginInformation.password };
    }

    return signin(data, setFieldError, {
      goToHome: !isModal,
      redirectToLastPlace: withRedirectToLastPlace,
      requestOptions: {
        errorOptions: {
          422: (error: any) => ExceptionOf422(error, { setFieldError }),
        },
      },
    }).then((res: any) => {
      if (typeof afterLogin === 'function') {
        afterLogin(res);
      }
      return res;
    });
  };

  return (
    <>
      <Box sx={{ mb: '60px' }}>
        <AuthFormHeader
          isModal={isModal}
          title={
            <>
              <FormattedMessage id="Login.check-email-or-sms.title" />
              <FormattedMessage
                id={
                  loginInformation.type === 'email'
                    ? 'Login.check-email-or-sms.emails.title'
                    : 'Login.check-email-or-sms.phone.title'
                }
              />
            </>
          }
          description={
            <>
              <FormattedMessage id="Login.check-email-or-sms.description.send-verification-code.title" />
              <span style={{ marginLeft: '3px' }}>{loginInformation.masked}</span>
            </>
          }
        />
      </Box>
      <Formik
        initialValues={{
          two_factor_auth_code: '',
        }}
        validationSchema={SecureStepFormValidationSchema}
        onSubmit={submitHandler}
      >
        {() => (
          <Form>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} className="mb-2">
                  <Field
                    name="two_factor_auth_code"
                    component={TextInput}
                    placeholder={formatMessage({ id: 'Login.check-email-or-sms.2FA-code-input-placeholder' })}
                    startAdornment={
                      <Fragment>
                        <FontAwesomeIcon icon={faKey} className="mr-3 color-theme" />
                      </Fragment>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    type="submit"
                    className="w-100 d-block b2b-auth-layout-submit-button"
                  >
                    <FormattedMessage id="Login.check-email.continue-btn" />
                  </Button>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: '60px' }}>
                  <Grid container item xs={6} className="align-items-center">
                    <Button
                      style={isModal ? { color: '#092A91' } : { color: 'white' }}
                      className="p-0 bg-transparent"
                      onClick={() => {
                        loginFormStepHandler(0);
                      }}
                    >
                      <FontAwesomeIcon icon={direction === 'ltr' ? faArrowLeft : faArrowRight} className="mr-1" />
                      <Box className="text-decoration-underline">
                        <FormattedMessage id="Login.check-email.back-to-login-btn" />
                      </Box>
                    </Button>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    style={isModal ? { color: '#092A91' } : { color: 'white' }}
                    className="justify-content-end align-items-center"
                  >
                    <Grid container className="justify-content-end" spacing={1}>
                      {!timer.hasDeadlineArrived ? (
                        <Grid item className="direction-ltr">
                          {timer.minutes} : {timer.seconds}
                        </Grid>
                      ) : (
                        <Box className=" align-items-center">
                          <FormattedMessage id="Login.check-email.email-receive-question" />
                          <Button
                            className="text-decoration-underline ml-1 p-0 bg-transparent"
                            style={isModal ? { color: '#092A91' } : { color: 'white' }}
                            onClick={() => {
                              send2FACodeHandler().then(() => {
                                window.openSnackbar(
                                  <FormattedMessage id="Login.verification-code-resend-successfully.message" />,
                                  'success'
                                );
                                setTimerDeadline(getTimerDeadline());
                                timer.restart();
                              });
                            }}
                          >
                            <FormattedMessage id="Login.check-email.resend-email-btn" />
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SecureStepForm;
