import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';

const ViewTicketList = lazyWithRetry(() =>
  import(/* webpackChunkName: "view-tickets-list" */ 'views/app/support/list')
);

const ViewNewTicket = lazyWithRetry(() =>
  import(/* webpackChunkName: "view-ticket-creation" */ 'views/app/support/new')
);

const TicketView = lazyWithRetry(() => import(/* webpackChunkName: "ticket-view" */ 'views/app/support/reply'));

const supportRoutes = [
  {
    id: 'support/list',
    Component: ViewTicketList,
    path: urls.app.support.list,
  },

  {
    id: 'support/reply',
    Component: TicketView,
    path: urls.app.support.reply,
  },

  {
    id: 'support/new',
    Component: ViewNewTicket,
    path: urls.app.support.new,
  },
];

export default supportRoutes;
