import { calcSum } from '../index';
import Gateway from '../models/common/Gateway';
import { Calculator } from '@hotelian/helpers';

export const getGatewayByName = (selectedGatewayName: Gateway['name'], availableGateways: Gateway[]) => {
  return availableGateways?.find(el => el.name === selectedGatewayName);
};

export const addGatewayFeeToPrice = (price: number, feePercentage: number) => {
  let gatewayCost = 0;
  if (feePercentage && feePercentage > 0) {
    gatewayCost = Calculator.roundUp((feePercentage / 100) * Number(price), 2);
  }
  const finalPrice = Calculator.roundUp(calcSum([Number(price), gatewayCost]), 2);

  return { finalPrice, gatewayCost };
};
